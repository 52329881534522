<template>
    <div class="header-nav-sub">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'HeaderSubMenuForEvent',
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@include g.pc {
    .header-nav-sub {
        display: none;
        position: absolute;
        top: g.$header-height-pc;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 856px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        background-color: g.$color-white;

        &::before {
            content: "";
            position: fixed;
            top: 60px;
            left: 0;
            right: 0;
            width: 920px;
            height: 38px;
            margin: 0 auto;
            z-index: 0;
        }

        &::after {
            content: '';
            position: fixed;
            top: g.$header-height-pc;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background-color: rgba(g.$color-black, .5);
            pointer-events: none;
        }

        ::v-slotted(.link) {
            & > li {
                & > a:not(.c-btn) {
                    position: relative;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 150px;
                    height: 40px;
                    font-size: 13px;
                    color: g.$color-font-white !important;
                    background: g.$color-blue;
                    border-radius: 4px;
                    @include g.pc {
                        &:not(.c-btn):hover {
                            background: g.$color-green;
                        }
                    }
                    &::after {
                        @include g.setIconFont('\e909');
                        position: absolute;
                        font-size: 10px;
                        right: 12px;
                    }
                }
            }
        }
    }
}
@include g.sp {
    .header-nav-sub {
        ::v-slotted(.link) {
            & > li {
                margin-left: 19px;
                margin-right: 0;
                a {
                    height: 60px;
                    padding-left: g.$margin-large;
                    font-size: 18px;
                    font-weight: normal;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
</style>
