<template>
    <section class="c-content">
        <div class="u-text-center u-mb-lg">
            <p class="u-text page-description">以下の内容で登録します。よろしいですか？</p>
            <error-msg
                v-if="error"
                class="u-mb-lg pc-width-640"
            >
                <p><pre>{{ error }}</pre></p>
            </error-msg>
        </div>
        <form action="post">
            <div class="form u-mb c-table-list">
                <div class="c-table-list-row">
                    <div class="column-left">
                        メールアドレス
                    </div>
                    <div class="column-right">
                        {{ syncedMail }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        ニックネーム
                    </div>
                    <div class="column-right">
                        {{ syncedNickname }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        お名前
                    </div>
                    <div class="column-right">
                        {{ syncedLastName }} {{ syncedFirstName }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        フリガナ
                    </div>
                    <div class="column-right">
                        {{ syncedLastNameKana }} {{ syncedFirstNameKana }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        生年月日
                    </div>
                    <div class="column-right">
                        {{ dayjsFormat(syncedBirthday, 'YYYY年MM月DD日') }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        性別
                    </div>
                    <div class="column-right">
                        {{ dispGender }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        郵便番号
                    </div>
                    <div class="column-right">
                        {{ syncedPostcode }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        都道府県
                    </div>
                    <div class="column-right">
                        {{ dispPrefectures }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        住所
                    </div>
                    <div class="column-right">
                        {{ syncedAddress }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        携帯電話番号
                    </div>
                    <div class="column-right">
                        {{ syncedPhoneNumber }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        マイページ<br class="u-display-pc">公開/非公開
                    </div>
                    <div class="column-right">
                        {{ dispMypage }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        プロモーション<br class="u-display-pc">メール
                    </div>
                    <div class="column-right">
                        {{ dispPromo }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        アバター
                    </div>
                    <div class="column-right">
                        <div class="c-input-avatar-wrap" v-if="avatarInfo">
                            <div class="c-input c-input-avatar">
                                <input id="avatar-1" type="radio" disabled>
                                <label for="avatar-1">
                                    <img :src="avatarInfo.image_url">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        パスワード
                    </div>
                    <div class="column-right">
                        {{ dispPassword }}
                    </div>
                </div>
            </div>
            <div class="u-text-center warning-text">
                <p class="u-width-100 u-text-danger">お名前、フリガナ、生年月日は<br>登録後に変更することはできません。</p>
            </div>
            <div class="u-text-center button-container">
                <button class="c-btn c-btn-primary" type="button" @click="resister()">登録する</button>
                <button class="c-btn c-btn-primary-outline" type="button" @click="back()">修正する</button>
            </div>
        </form>
    </section>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    name: 'UserRegisterConfirmPage',
    components: {},
    props: {
        email: String,
        nickname: String,
        lastName: String,
        firstName: String,
        lastNameKana: String,
        firstNameKana: String,
        birthdayYear: String,
        birthdayMonth: String,
        birthdayDay: String,
        gender: String,
        postcode: String,
        prefectures: String,
        address: String,
        phoneNumber: Number,
        mypage: String,
        promotionalEmail: String,
        parentalConsent: Boolean,
        termsOfService: Boolean,
        password: String,
        passwordReEnter: String,
        avatarInfo: Object,
        authCode: String
    },
    data: function() {
        return {
            dispPrefectures: '',
            dispGender: '',
            dispMypage: '',
            dispPromo: '',
            dispPassword: '',
            error: null,
        }
    },
    computed: {
        syncedMail: {
            get() {
                return this.email
            },
            set(value) {
                this.$emit('update:email', value)
            }
        },
        syncedBirthday: {
            get() {
                return this.birthdayYear + '/' + this.birthdayMonth + '/' + this.birthdayDay
            }
        },
        syncedBirthdayYear: {
            get() {
                return this.birthdayYear
            },
            set(value) {
                this.$emit('update:birthdayYear', value)
            }
        },
        syncedBirthdayMonth: {
            get() {
                return this.birthdayMonth
            },
            set(value) {
                this.$emit('update:birthdayMonth', value)
            }
        },
        syncedBirthdayDay: {
            get() {
                return this.birthdayDay
            },
            set(value) {
                this.$emit('update:birthdayDay', value)
            }
        },
        syncedNickname: {
            get() {
                return this.nickname
            },
            set(value) {
                this.$emit('update:nickname', value)
            }
        },
        syncedLastName: {
            get() {
                return this.lastName
            },
            set(value) {
                this.$emit('update:lastName', value)
            }
        },
        syncedFirstName: {
            get() {
                return this.firstName
            },
            set(value) {
                this.$emit('update:firstName', value)
            }
        },
        syncedLastNameKana: {
            get() {
                return this.lastNameKana
            },
            set(value) {
                this.$emit('update:lastNameKana', value)
            }
        },
        syncedFirstNameKana: {
            get() {
                return this.firstNameKana
            },
            set(value) {
                this.$emit('update:firstNameKana', value)
            }
        },
        syncedGender: {
            get() {
                return this.gender
            },
            set(value) {
                this.$emit('update:gender', value)
            }
        },
        syncedPostcode: {
            get() {
                return this.postcode
            },
            set(value) {
                this.$emit('update:postcode', value)
            }
        },
        syncedPrefectures: {
            get() {
                return this.prefectures
            },
            set(value) {
                this.$emit('update:prefectures', value)
            }
        },
        syncedAddress: {
            get() {
                return this.address
            },
            set(value) {
                this.$emit('update:address', value)
            }
        },
        syncedPhoneNumber: {
            get() {
                return this.phoneNumber
            },
            set(value) {
                this.$emit('update:phoneNumber', value)
            }
        },
        syncedMypage: {
            get() {
                return this.mypage
            },
            set(value) {
                this.$emit('update:mypage', value)
            }
        },
        syncedPromotionalEmail: {
            get() {
                return this.promotionalEmail
            },
            set(value) {
                this.$emit('update:promotionalEmail', value)
            }
        },
        syncedPassword: {
            get() {
                return this.password
            },
            set(value) {
                this.$emit('update:password', value)
            }
        },
        syncedPasswordReEnter: {
            get() {
                return this.passwordReEnter
            },
            set(value) {
                this.$emit('update:passwordReEnter', value)
            }
        },
    },
    created: function() {
        this.init()
    },
    methods: {
        resister() {
            let params = new URLSearchParams
            params.append('nickname',this.nickname)
            params.append('firstname',this.firstName)
            params.append('firstname_kana',this.firstNameKana)
            params.append('familyname',this.lastName)
            params.append('familyname_kana',this.syncedLastNameKana)
            params.append('mail_address',this.email)
            params.append('auth_code',this.authCode)
            params.append('sex_id',this.gender)
            params.append('prefecture_id',this.prefectures.split(':')[0])
            params.append('birthday',this.syncedBirthday)
            params.append('phone_number',this.phoneNumber)
            params.append('avatar_id','1') //1固定
            params.append('publish_flg',this.mypage)
            params.append('postal_code',this.postcode)
            params.append('address',this.address)
            params.append('mail_notification_flg',this.promotionalEmail)
            params.append('password',this.password)

            const api = '/regist_player'
            this.axios.post(api, params)
            .then(response => {
                this.$store.commit('setPhoneNumber', this.phoneNumber)
                this.$store.commit('setMail', this.email)
                this.$router.push({name: 'UserRegisterComplete' , params: { league: response.data.league }})
            })
            .catch(err =>{
                if (err.response.status === 404) {
                    this.$router.push({name: 'UserRegisterError',params: { message: err.response.data.message }})
                }
                this.error = err.response.data.message
            })
        },
        back() {
            this.syncedPassword = ''
            this.syncedPasswordReEnter = ''
            this.$router.push({name: 'UserRegister'})
        },
        init() {
            this.dispPrefectures = this.prefectures.split(':')[1]
            if (this.gender === '1')
            {
                this.dispGender = '男性'
            }
            else if (this.gender === '2')
            {
                this.dispGender = '女性'
            }
            else
            {
                this.dispGender = '指定しない'
            }

            this.dispMypage = this.mypage == 1 ? '公開' : '非公開'
            this.dispPromo = this.promotionalEmail == 1 ? '受け取る' : '受け取らない'
            this.dispPassword ='*'.repeat(this.password.length)
        },
        dayjsFormat(date, format) {
            dayjs.locale(ja)
            if (date !== null) {
                return dayjs(date).format(format)
            }
            return format
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.c-content {
    @include g.pc {
        width: 760px;
    }
}
.c-title-page {
    color: g.$color-black;
    margin-bottom: 36px;
}
.page-description {
    color: g.$color-black;
    @include g.pc {
        margin-bottom: 56px;
    }
    @include g.sp {
        margin-bottom: 28px;
    }
}

.c-table-list {
    border-top: 1px solid #B3D8EE;
    border-bottom: 1px solid #B3D8EE;
    @include g.pc {
        padding-top: 56px;
        padding-bottom: 56px;
    }
    @include g.sp {
        padding-top: 33px;
        padding-bottom: 33px;
    }

    &::before {
        border: 0;
    }
    &-row {
        &:nth-of-type(1) {
            border-top: 0;
        }
        &:not(:last-child) {
            border-bottom: 0;
        }
        .column {
            @include g.pc {
                &-left {
                    position: relative;
                    flex: 0 0 220px;
                    padding-right: 0;
                    padding-left: 30px;
                    padding-top: 22px;
                    padding-bottom: 22px;
                    box-sizing: content-box;
                }
                &-right {
                    padding-right: 30px;
                    padding-left: 0;
                    padding-top: 22px;
                    padding-bottom: 22px;
                }
            }
            @include g.sp {
                &-left {
                    font-size: 14px;
                    height: 20px;
                    line-height: 20px;
                    padding: 0;
                    margin-bottom: 10px;
                }
                &-right {
                    font-size: 16px;
                    min-height: 24px;
                    line-height: 24px;
                    padding: 0;
                    margin-bottom: 32px;
                }
            }
            &-left {
                background-color: g.$color-white;
            }
        }
    }
}

.button-container {
    @include g.sp {
        padding: 0 10px;
    }
    .c-btn {
        margin: 0 auto g.$margin;
        display: block;
        &:not(:first-child) {
            margin-top: g.$margin;
        }
    }
}

.no-border {
    padding-top: 0;
    border: 0;
}

.warning-text {
    padding-top: 56px;
    padding-bottom: 41px;
    @include g.sp {
        padding-top: 48px;
        padding-bottom: 33px;
    }
}
</style>
