<template>
			<!-- 以下ページコンテンツ -->
			<div class="eventTop" data-current-type="">
				<!-- 導入画面 -->
				<div class="introduction">
					<div class="introduction_inner">
						<div class="introduction_box">
							<div class="introduction_box_bg">
								<img
									src="@/views/Event/Top/src/images/entrance/bg_ball.png"
									alt=""
								/>
							</div>
							<div class="introduction_box_card" data-type="left">
								<picture>
									<source
										srcset="
											@/views/Event/Top/src/images/entrance/image_bgcard_l_sp.png
										"
										media="(max-width: 768px)"
									/>
									<img
										src="@/views/Event/Top/src/images/entrance/image_bgcard_l.png"
										alt=""
									/>
								</picture>
							</div>
							<div
								class="introduction_box_card"
								data-type="right"
							>
								<picture>
									<source
										srcset="
											@/views/Event/Top/src/images/entrance/image_bgcard_r_sp.png
										"
										media="(max-width: 768px)"
									/>
									<img
										src="@/views/Event/Top/src/images/entrance/image_bgcard_r.png"
										alt=""
									/>
								</picture>
							</div>
							<figure class="introduction_box_message">
								<picture>
									<source
										srcset="
											@/views/Event/Top/src/images/entrance/image_select-message_sp.png
										"
										media="(max-width: 768px)"
									/>
									<img
										src="@/views/Event/Top/src/images/entrance/image_select-message.png"
										alt="ポケカのイベント きみはどっち？"
									/>
								</picture>
							</figure>
							<div
								class="introduction_box_item"
								data-type="enjoy"
                                @click="setHash('#enjoy')"
							>
								<div
									class="photoCard introduction_box_item_card"
									data-type="enjoy"
								>
									<p class="photoCard_title">
										<img
											src="@/views/Event/Top/src/images/entrance/text_kvcard-enjoy.png"
											alt="ENJOY"
										/>
									</p>

									<div class="photoCard_image">
										<img
											src="@/views/Event/Top/src/images/common/image_kv-enjoy.jpg"
											alt=""
										/>
									</div>
								</div>

								<div class="introduction_box_item_info">
									<button
										class="introduction_box_item_button"
										data-type="enjoy"
									>
										<span
											class="introduction_box_item_button_arrow"
										>
											<svg
												viewBox="0 0 28 15"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												class="animationArrow"
												data-type="enjoy"
											>
												<path
													d="M6.29443 13.5892L12.5891 7.29462L6.29443 0.999999"
													stroke-opacity="0.25"
													stroke-width="2.31821"
												/>
												<path
													d="M13.1841 13.5892L19.4787 7.29462L13.1841 0.999999"
													stroke-opacity="0.5"
													stroke-width="2.31821"
												/>
												<path
													d="M20.0732 13.5892L26.3679 7.29462L20.0732 0.999999"
													stroke-width="2.31821"
												/>
											</svg>
										</span>

										<span
											class="introduction_box_item_button_text"
										>
											<img
												class="introduction_box_item_button_img"
												src="@/views/Event/Top/src/images/common/btn_text-enjyoy.png"
												alt="エンジョイ!"
											/>
											<img
												class="introduction_box_item_button_img"
												data-hover
												src="@/views/Event/Top/src/images/common/btn_text-enjyoy_hover.png"
												alt="エンジョイ"
											/>
										</span>
									</button>

									<p
										class="introduction_box_item_info_comment"
									>
										<img
											src="@/views/Event/Top/src/images/entrance/text_enjyoy-comment.png"
											alt="ポケカのイベントをカジュアルに楽しもう"
										/>
									</p>
								</div>
							</div>

							<div
								class="introduction_box_item"
								data-type="challenge"
                                @click="setHash('#challenge')"
							>
								<div
									class="photoCard introduction_box_item_card"
									data-type="challenge"
								>
									<p class="photoCard_title">
										<img
											src="@/views/Event/Top/src/images/entrance/text_kvcard-challenge.png"
											alt="CHALLENGE"
										/>
									</p>

									<div class="photoCard_image">
										<img
											src="@/views/Event/Top/src/images/common/image_kv-challenge.jpg"
											alt=""
										/>
									</div>
								</div>

								<div class="introduction_box_item_info">
									<button
										class="introduction_box_item_button"
										data-type="challenge"
									>
										<span
											class="introduction_box_item_button_text"
										>
											<img
												src="@/views/Event/Top/src/images/common/btn_text-challenge.png"
												alt="チャレンジ!"
											/>
											<img
												data-hover
												src="@/views/Event/Top/src/images/common/btn_text-challenge_hover.png"
												alt="チャレンジ!"
											/>
										</span>

										<span
											class="introduction_box_item_button_arrow"
										>
											<svg
												viewBox="0 0 28 15"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												class="animationArrow"
												data-type="challenge"
											>
												<path
													d="M6.29443 13.5892L12.5891 7.29462L6.29443 0.999999"
													stroke-opacity="0.25"
													stroke-width="2.31821"
												/>
												<path
													d="M13.1841 13.5892L19.4787 7.29462L13.1841 0.999999"
													stroke-opacity="0.5"
													stroke-width="2.31821"
												/>
												<path
													d="M20.0732 13.5892L26.3679 7.29462L20.0732 0.999999"
													stroke-width="2.31821"
												/>
											</svg>
										</span>
									</button>

									<p
										class="introduction_box_item_info_comment"
									>
										<img
											src="@/views/Event/Top/src/images/entrance/text_challenge-comment.png"
											alt="お気に入りのデッキでイベントに参加しよう"
										/>
									</p>
								</div>
							</div>
						</div>
						<div class="introduction_bottom">
							<div class="introduction_bottom_bg">
								<picture>
									<source
										srcset="
											@/views/Event/Top/src/images/entrance/image_playmat_sp.png
										"
										media="(max-width: 768px)"
									/>
									<img
										src="@/views/Event/Top/src/images/entrance/image_playmat.png"
										alt=""
									/>
								</picture>
							</div>

							<figure
								class="introduction_bottom_character"
								data-index="1"
							>
								<img
									src="@/views/Event/Top/src/images/entrance/image_character_01.png"
									alt=""
								/>
							</figure>

							<figure
								class="introduction_bottom_character"
								data-index="2"
							>
								<img
									src="@/views/Event/Top/src/images/entrance/image_character_02.png"
									alt=""
								/>
							</figure>

							<figure
								class="introduction_bottom_character"
								data-index="3"
							>
								<img
									src="@/views/Event/Top/src/images/entrance/image_character_03.png"
									alt=""
								/>
							</figure>

							<figure
								class="introduction_bottom_character"
								data-index="4"
							>
								<img
									src="@/views/Event/Top/src/images/entrance/image_character_04.png"
									alt=""
								/>
							</figure>
						</div>
					</div>
				</div>
				<!-- トランジッション画面 -->
				<div
					class="transition"
					data-transition="before"
					data-type="enjoy"
				>
					<div class="transition_inner">
						<div
							class="transition_border"
							data-position="top"
						></div>

						<div class="transition_content" data-type="enjoy">
							<p>
								<img
									src="@/views/Event/Top/src/images/transition/text_enjoy.png"
									alt="ENJOY POKÉCA EVENT"
								/>
							</p>
						</div>

						<div class="transition_content" data-type="challenge">
							<p>
								<img
									src="@/views/Event/Top/src/images/transition/text_challenge.png"
									alt="CHALLENGE POKÉCA EVENT"
								/>
							</p>
						</div>

						<div class="transition_card">
							<img
								src="@/views/Event/Top/src/images/transition/image_card.png"
								alt=""
							/>
						</div>

						<div
							class="transition_border"
							data-position="bottom"
						></div>
					</div>
				</div>

				<!-- ページ内共通ヘッダー -->
				<div class="eventTop_header">
					<div class="eventTop_header_slide" data-type="enjoy">
						<div class="eventTop_header_slide_copy">
							<div class="eventTop_header_slide_copy_line"></div>
						</div>
						<div class="eventTop_header_inner">
							<div class="eventTop_header_inner_bg">
								<img
									src="@/views/Event/Top/src/images/common/bg_ball.png"
									alt=""
								/>
							</div>
							<div class="eventTop_header_card">
								<img
									src="@/views/Event/Top/src/images/enjoy/image_kv-card.png"
									alt=""
								/>
							</div>
							<div class="eventTop_header_card" data-photo>
								<div class="photoCard" data-type="enjoy">
									<p class="photoCard_title">
										<img
											src="@/views/Event/Top/src/images/entrance/text_kvcard-enjoy.png"
											alt="ENJOY"
										/>
									</p>

									<div class="photoCard_image">
										<img
											src="@/views/Event/Top/src/images/common/image_kv-enjoy.jpg"
											alt=""
										/>
									</div>
								</div>
							</div>

							<div class="eventTop_header_box">
								<h1 class="eventTop_header_lead">
									<picture>
										<source
											srcset="
												@/views/Event/Top/src/images/enjoy/text_kv_sp.png
											"
											media="(max-width: 768px)"
										/>
										<img
											src="@/views/Event/Top/src/images/enjoy/text_kv.png"
											alt="ポケカのイベント ENJOY"
										/>
									</picture>
								</h1>

								<nav class="eventTop_header_nav">
									<div class="eventTop_header_ui_button">
										<button
											class="eventTop_header_nav_button"
											data-type="enjoy"
                                            @click="setHash('#enjoy')"
										>
											<span
												class="eventTop_header_nav_button_text"
											>
												<img
													class="eventTop_header_nav_button_img"
													src="@/views/Event/Top/src/images/common/btn_text-enjyoy_hover.png"
													alt="エンジョイ"
												/>
											</span>
										</button>
									</div>
									<div class="eventTop_header_ui_button">
										<button
											class="eventTop_header_nav_button"
											data-type="challenge"
                                            @click="setHash('#challenge')"
										>
											<span
												class="eventTop_header_nav_button_text"
											>
												<img
													class="eventTop_header_nav_button_img"
													src="@/views/Event/Top/src/images/common/btn_text-challenge.png"
													alt="チャレンジ"
												/>
												<img
													data-hover
													class="eventTop_header_nav_button_img"
													src="@/views/Event/Top/src/images/common/btn_text-challenge_hover.png"
													alt="チャレンジ"
												/>
											</span>

											<span
												class="eventTop_header_nav_button_arrow"
											>
												<svg
													viewBox="0 0 28 15"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
													class="animationArrow"
													data-type="challenge"
												>
													<path
														d="M6.29443 13.5892L12.5891 7.29462L6.29443 0.999999"
														stroke-opacity="0.25"
														stroke-width="2.31821"
													/>
													<path
														d="M13.1841 13.5892L19.4787 7.29462L13.1841 0.999999"
														stroke-opacity="0.5"
														stroke-width="2.31821"
													/>
													<path
														d="M20.0732 13.5892L26.3679 7.29462L20.0732 0.999999"
														stroke-width="2.31821"
													/>
												</svg>
											</span>
										</button>
									</div>
								</nav>
							</div>
						</div>
					</div>
					<div class="eventTop_header_slide" data-type="challenge">
						<div class="eventTop_header_slide_copy">
							<div class="eventTop_header_slide_copy_line"></div>
						</div>
						<div class="eventTop_header_inner">
							<div class="eventTop_header_inner_bg">
								<img
									src="@/views/Event/Top/src/images/common/bg_ball.png"
									alt=""
								/>
							</div>
							<div class="eventTop_header_card">
								<img
									src="@/views/Event/Top/src/images/challenge/image_kv-card.png"
									alt=""
								/>
							</div>
							<div class="eventTop_header_card" data-photo>
								<div class="photoCard" data-type="challenge">
									<p class="photoCard_title">
										<img
											src="@/views/Event/Top/src/images/entrance/text_kvcard-challenge.png"
											alt="CHALLENGE"
										/>
									</p>

									<div class="photoCard_image">
										<img
											src="@/views/Event/Top/src/images/common/image_kv-challenge.jpg"
											alt=""
										/>
									</div>
								</div>
							</div>

							<div class="eventTop_header_box">
								<h1 class="eventTop_header_lead">
									<picture>
										<source
											srcset="
												@/views/Event/Top/src/images/challenge/text_kv_sp.png
											"
											media="(max-width: 768px)"
										/>
										<img
											src="@/views/Event/Top/src/images/challenge/text_kv.png"
											alt="ポケカのイベント CHALLENGE"
										/>
									</picture>
								</h1>

								<nav class="eventTop_header_nav">
									<div class="eventTop_header_ui_button">
										<button
											class="eventTop_header_nav_button"
											data-type="enjoy"
                                            @click="setHash('#enjoy')"
										>
											<span
												class="eventTop_header_nav_button_arrow"
											>
												<svg
													viewBox="0 0 28 15"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
													class="animationArrow"
													data-type="enjoy"
												>
													<path
														d="M6.29443 13.5892L12.5891 7.29462L6.29443 0.999999"
														stroke-opacity="0.25"
														stroke-width="2.31821"
													/>
													<path
														d="M13.1841 13.5892L19.4787 7.29462L13.1841 0.999999"
														stroke-opacity="0.5"
														stroke-width="2.31821"
													/>
													<path
														d="M20.0732 13.5892L26.3679 7.29462L20.0732 0.999999"
														stroke-width="2.31821"
													/>
												</svg>
											</span>
											<span
												class="eventTop_header_nav_button_text"
											>
												<img
													class="eventTop_header_nav_button_img"
													src="@/views/Event/Top/src/images/common/btn_text-enjyoy.png"
													alt="エンジョイ"
												/>
												<img
													data-hover
													class="eventTop_header_nav_button_img"
													src="@/views/Event/Top/src/images/common/btn_text-enjyoy_hover.png"
													alt="エンジョイ"
												/>
											</span>
										</button>
									</div>
									<div class="eventTop_header_ui_button">
										<button
											class="eventTop_header_nav_button"
											data-type="challenge"
                                            @click="setHash('#challenge')"
										>
											<span
												class="eventTop_header_nav_button_text"
												><img
													class="eventTop_header_nav_button_img"
													src="@/views/Event/Top/src/images/common/btn_text-challenge_hover.png"
													alt="チャレンジ"
												/>
											</span>
										</button>
									</div>
								</nav>
							</div>
						</div>
					</div>

					<!-- SP用ナビ -->
					<nav class="eventTop_header_spNav">
						<div class="eventTop_header_ui_button">
							<button
								class="eventTop_header_nav_button"
								data-type="enjoy"
                                @click="setHash('#enjoy')"
							>
								<span class="eventTop_header_nav_button_arrow">
									<svg
										viewBox="0 0 28 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										class="animationArrow"
										data-type="enjoy"
									>
										<path
											d="M6.29443 13.5892L12.5891 7.29462L6.29443 0.999999"
											stroke-opacity="0.25"
											stroke-width="2.31821"
										/>
										<path
											d="M13.1841 13.5892L19.4787 7.29462L13.1841 0.999999"
											stroke-opacity="0.5"
											stroke-width="2.31821"
										/>
										<path
											d="M20.0732 13.5892L26.3679 7.29462L20.0732 0.999999"
											stroke-width="2.31821"
										/>
									</svg>
								</span>
								<span class="eventTop_header_nav_button_text">
									<img
										class="eventTop_header_nav_button_img"
										src="@/views/Event/Top/src/images/common/btn_text-enjyoy.png"
										alt="エンジョイ"
									/>
									<img
										class="eventTop_header_nav_button_img"
										data-hover
										src="@/views/Event/Top/src/images/common/btn_text-enjyoy_hover.png"
										alt="エンジョイ"
									/>
								</span>
							</button>
						</div>
						<div class="eventTop_header_ui_button">
							<button
								class="eventTop_header_nav_button"
								data-type="challenge"
                                @click="setHash('#challenge')"
							>
								<span class="eventTop_header_nav_button_text">
									<img
										class="eventTop_header_nav_button_img"
										src="@/views/Event/Top/src/images/common/btn_text-challenge.png"
										alt="チャレンジ"
									/>
									<img
										data-hover
										class="eventTop_header_nav_button_img"
										src="@/views/Event/Top/src/images/common/btn_text-challenge_hover.png"
										alt="チャレンジ"
									/>
								</span>

								<span class="eventTop_header_nav_button_arrow">
									<svg
										viewBox="0 0 28 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										class="animationArrow"
										data-type="challenge"
									>
										<path
											d="M6.29443 13.5892L12.5891 7.29462L6.29443 0.999999"
											stroke-opacity="0.25"
											stroke-width="2.31821"
										/>
										<path
											d="M13.1841 13.5892L19.4787 7.29462L13.1841 0.999999"
											stroke-opacity="0.5"
											stroke-width="2.31821"
										/>
										<path
											d="M20.0732 13.5892L26.3679 7.29462L20.0732 0.999999"
											stroke-width="2.31821"
										/>
									</svg>
								</span>
							</button>
						</div>
					</nav>
				</div>

				<!-- エンジョイ -->
				<div class="eventTop_contents eventEnjoy" data-type="enjoy">
					<section class="eventEnjoy_first">
						<div class="eventTop_inner">
							<h2 class="eventEnjoy_first_title">
								<img
									src="@/views/Event/Top/src/images/enjoy/title_first.png"
									alt="はじめての ポケモンカードイベント"
								/>
							</h2>

							<figure
								class="eventEnjoy_first_pv"
								data-micromodal-trigger="modal_youtube"
								data-youtube-id="xPH_9-sw3Gs"
							>
								<div class="eventEnjoy_first_pv_inner">
									<video
										class="eventEnjoy_first_pv_video"
										src="@/views/Event/Top/src/images/enjoy/movie.mp4"
										autoplay
										muted
										loop
										playsinline
									></video>
								</div>
							</figure>

							<p class="eventEnjoy_first_lead">
								<picture>
									<source
										srcset="
											@/views/Event/Top/src/images/enjoy/text_lead_sp.png
										"
										media="(max-width: 768px)"
									/>
									<img
										src="@/views/Event/Top/src/images/enjoy/text_lead.png"
										alt="まず知ろう！ ポケモンカードのイベントとは？"
									/>
								</picture>
							</p>

							<ul class="eventEnjoy_first_menu">
								<li class="eventEnjoy_first_menu_item">
									<button
										class="eventEnjoy_first_menu_button"
										data-micromodal-trigger="modal_enjoy_howto"
									>
										イベントの
										<br data-sp />
										楽しみ方が
										<br />
										知りたい！
									</button>
								</li>
								<li class="eventEnjoy_first_menu_item">
									<button
										class="eventEnjoy_first_menu_button"
										data-micromodal-trigger="modal_enjoy_recommend"
									>
										はじめての
										<br data-sp />
										参加に
										<br />
										おすすめは？
									</button>
								</li>
								<li class="eventEnjoy_first_menu_item">
									<button
										class="eventEnjoy_first_menu_button"
										data-micromodal-trigger="modal_enjoy_join"
									>
										イベントに
										<br data-sp />
										参加
										<br />
										するには？
									</button>
								</li>
							</ul>

							<article class="eventEnjoy_first_register">
								<figure class="eventEnjoy_first_register_logo">
									<img
										src="@/views/Event/Top/src/images/common/logo_players.svg"
										alt="ポケモンカードゲーム プレイヤーズクラブ"
									/>
								</figure>
								<div class="eventEnjoy_first_register_inner">
									<h3 class="eventEnjoy_first_register_title">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/enjoy/title_players_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/enjoy/title_players.png"
												alt="「プレイヤーズクラブ」に登録しよう！"
											/>
										</picture>
									</h3>

									<p class="eventEnjoy_first_register_text">
										ポケモンカードゲームのイベントに参加するには、<br />
										事前に「ポケモンカードゲーム
										プレイヤーズクラブ」に登録する必要があるよ。<br />
										まずは会員登録を行おう！
									</p>

									<div class="eventEnjoy_first_register_box">
                                        <router-link
                                            :to="{name: 'Mypage'}"
                                            class="eventTop_linkBtn"
                                            >マイページ</router-link>
                                        <router-link
                                            :to="{name: 'MailRegister'}"
                                            class="eventTop_linkBtn"
                                        >新規会員登録</router-link>
									</div>

									<div
										class="eventEnjoy_first_register_question"
									>
                                        <router-link
                                            :to="{name: 'Faq'}"
                                            class="eventEnjoy_first_register_question_link"
                                        >
                                            ポケモンカードゲーム
                                            プレイヤーズクラブとは？
                                        </router-link>
									</div>
								</div>
							</article>
						</div>
					</section>

					<section class="eventEnjoy_event">
						<div class="eventTop_inner">
							<h2 class="eventTop_section_title">
								<img
									src="@/views/Event/Top/src/images/enjoy/title_event.png"
									alt="イベントの種類を知ろう"
								/>
							</h2>
							<p class="eventTop_section_lead">
								たくさんのイベントから
								<br />
								ぴったりのイベントを見つけよう！
							</p>

							<div
								class="eventEnjoy_event_tab"
								data-current-tab="1"
							>
								<nav class="eventEnjoy_event_tab_menu">
									<ul class="eventEnjoy_event_tab_list">
										<li
											class="eventEnjoy_event_tab_list_item"
										>
											<button
												class="eventEnjoy_event_tab_button"
												data-index="1"
											>
												<span>
													<span>ルールを</span
													><span>教わる</span>
												</span>
											</button>
										</li>
										<li
											class="eventEnjoy_event_tab_list_item"
										>
											<button
												class="eventEnjoy_event_tab_button"
												data-index="2"
											>
												<span>
													<span>初心者</span
													><span>歓迎</span>
												</span>
											</button>
										</li>
										<li
											class="eventEnjoy_event_tab_list_item"
										>
											<button
												class="eventEnjoy_event_tab_button"
												data-index="3"
											>
												<span>真剣勝負</span>
											</button>
										</li>
										<li
											class="eventEnjoy_event_tab_list_item"
										>
											<button
												class="eventEnjoy_event_tab_button"
												data-index="4"
											>
												<span>
													<span>目指せ</span
													><span>世界一</span>
												</span>
											</button>
										</li>
									</ul>
								</nav>

								<div class="eventEnjoy_event_tab_content">
									<div
										class="eventEnjoy_event_tab_article"
										data-index="1"
									>
										<p
											class="eventEnjoy_event_tab_article_lead"
										>
											<span
												>初めてでも安心、あそび方の教室や</span
											><span
												>デッキの作り方を教わるイベントも！</span
											>
										</p>

										<div
											class="eventEnjoy_event_tab_article_box"
										>
											<div
												class="eventEnjoy_event_tab_article_item"
											>
												<div
													class="eventEnjoy_event_tab_article_item_head"
													data-type="icon"
												>
													<figure>
														<img
															src="@/views/Event/Top/src/images/enjoy/logo_rule_01.png"
															alt="ポケモンカードゲーム教室"
														/>
													</figure>
													<h3
														class="eventEnjoy_event_tab_article_item_head_title"
													>
														<span>ポケモン</span
														><span
															>カードゲーム教室</span
														>
													</h3>
												</div>

												<div
													class="eventEnjoy_event_tab_article_item_main"
												>
													<p
														class="eventEnjoy_event_tab_article_item_main_text"
													>
														参加賞付きのイベントで
														<br />
														気軽にバトルのルールを
														<br />
														教わろう！
													</p>

													<figure
														class="eventEnjoy_event_tab_article_item_main_image"
													>
														<img
															src="@/views/Event/Top/src/images/enjoy/image_rule_01.png"
															alt=""
														/>
													</figure>
												</div>
											</div>
											<div
												class="eventEnjoy_event_tab_article_item"
											>
												<div
													class="eventEnjoy_event_tab_article_item_head"
													data-type="icon"
												>
													<figure>
														<img
															src="@/views/Event/Top/src/images/enjoy/logo_rule_02.png"
															alt=""
														/>
													</figure>
													<h3
														class="eventEnjoy_event_tab_article_item_head_title"
													>
														<span
															class="eventEnjoy_event_tab_article_item_head_title_small"
															>公認自主イベント</span
														>
														<br />
														<span
															>ティーチング/</span
														><span>デッキ作り</span>
													</h3>
												</div>

												<div
													class="eventEnjoy_event_tab_article_item_main"
												>
													<p
														class="eventEnjoy_event_tab_article_item_main_text"
													>
														教わりながら、
														<br />
														自分だけのデッキを
														<br />
														作ってみよう！
													</p>
													<figure
														class="eventEnjoy_event_tab_article_item_main_image"
													>
														<img
															src="@/views/Event/Top/src/images/enjoy/image_rule_02.png"
															alt=""
														/>
													</figure>
												</div>
											</div>
										</div>
										<div
											class="eventEnjoy_event_tab_article_item_more"
										>
											<a
												href="https://www.pokemon-card.com/event/type/"
												class="eventTop_linkBtn"
												>イベント一覧はこちら</a
											>
										</div>
									</div>
									<div
										class="eventEnjoy_event_tab_article"
										data-index="2"
									>
										<p
											class="eventEnjoy_event_tab_article_lead"
										>
											<span>まだ対戦ルールに</span
											><span
												>不安があるような方でも、</span
											><span
												>バトルを楽しめるイベントがあるよ！</span
											>
										</p>

										<div
											class="eventEnjoy_event_tab_article_box"
										>
											<div
												class="eventEnjoy_event_tab_article_item"
											>
												<div
													class="eventEnjoy_event_tab_article_item_head"
													data-type="logo"
												>
													<figure>
														<img
															src="@/views/Event/Top/src/images/enjoy/logo_beginner_01.png"
															alt="デッキそのままバトル ~イーブイゲットだぜ~"
														/>
													</figure>
												</div>

												<div
													class="eventEnjoy_event_tab_article_item_main"
												>
													<p
														class="eventEnjoy_event_tab_article_item_main_text"
													>
														すぐに遊べる構築済の
														<br />
														デッキで手軽にバトルを楽しもう！
													</p>

													<figure
														class="eventEnjoy_event_tab_article_item_main_image"
													>
														<img
															src="@/views/Event/Top/src/images/enjoy/image_beginner_01.png"
															alt=""
														/>
													</figure>
												</div>
											</div>
											<div
												class="eventEnjoy_event_tab_article_item"
											>
												<div
													class="eventEnjoy_event_tab_article_item_head"
													data-type="icon"
												>
													<figure>
														<img
															src="@/views/Event/Top/src/images/enjoy/logo_rule_02.png"
															alt=""
														/>
													</figure>
													<h3
														class="eventEnjoy_event_tab_article_item_head_title"
													>
														<span
															class="eventEnjoy_event_tab_article_item_head_title_small"
															>公認自主イベント</span
														>
														<br />
														初心者歓迎バトル
													</h3>
												</div>

												<div
													class="eventEnjoy_event_tab_article_item_main"
												>
													<p
														class="eventEnjoy_event_tab_article_item_main_text"
													>
														公認の「イベントオーガナイザー」開催の初⼼者歓迎対戦イベントだよ。
													</p>
													<figure
														class="eventEnjoy_event_tab_article_item_main_image"
													>
														<img
															src="@/views/Event/Top/src/images/enjoy/image_beginner_02.png"
															alt=""
														/>
													</figure>
												</div>
											</div>
										</div>
										<div
											class="eventEnjoy_event_tab_article_item_more"
										>
											<a
												href="https://www.pokemon-card.com/event/type/"
												class="eventTop_linkBtn"
												>イベント一覧はこちら</a
											>
										</div>
									</div>
									<div
										class="eventEnjoy_event_tab_article"
										data-index="3"
									>
										<p
											class="eventEnjoy_event_tab_article_lead"
										>
											<span>バトルに慣れてきたら</span
											><span
												>自分のデッキで戦うイベントに</span
											><span>参加してみよう！</span>
										</p>

										<div
											class="eventEnjoy_event_tab_article_box"
										>
											<div
												class="eventEnjoy_event_tab_article_item"
											>
												<div
													class="eventEnjoy_event_tab_article_item_head"
													data-type="logo"
												>
													<figure>
														<img
															src="@/views/Event/Top/src/images/enjoy/logo_thunder_01.png"
															alt=""
														/>
													</figure>
												</div>

												<div
													class="eventEnjoy_event_tab_article_item_main"
												>
													<p
														class="eventEnjoy_event_tab_article_item_main_text"
													>
														全国各地のポケモンカードジムでカジュアルにバトルしよう！
													</p>

													<figure
														class="eventEnjoy_event_tab_article_item_main_image"
													>
														<img
															src="@/views/Event/Top/src/images/enjoy/image_thunder_01.png"
															alt=""
														/>
													</figure>
												</div>
											</div>
											<div
												class="eventEnjoy_event_tab_article_item"
											>
												<div
													class="eventEnjoy_event_tab_article_item_head"
													data-type="logo"
												>
													<figure>
														<img
															src="@/views/Event/Top/src/images/enjoy/logo_thunder_02.png"
															alt="ティーチング/デッキ作り"
														/>
													</figure>
												</div>

												<div
													class="eventEnjoy_event_tab_article_item_main"
												>
													<p
														class="eventEnjoy_event_tab_article_item_main_text"
													>
														新商品のカードをデッキに入れて、いち早くバトルを楽しもう！
													</p>
													<figure
														class="eventEnjoy_event_tab_article_item_main_image"
													>
														<img
															src="@/views/Event/Top/src/images/enjoy/image_thunder_02.png"
															alt=""
														/>
													</figure>
												</div>
											</div>
										</div>
										<div
											class="eventEnjoy_event_tab_article_item_more"
										>
											<a
												href="https://www.pokemon-card.com/event/type/"
												class="eventTop_linkBtn"
												>イベント一覧はこちら</a
											>
										</div>
									</div>
									<div
										class="eventEnjoy_event_tab_article"
										data-index="4"
									>
										<p
											class="eventEnjoy_event_tab_article_lead"
										>
											<span
												>チャンピオンシップポイントを</span
											><span>獲得できるイベントで</span
											><span
												>プレイヤーランキング上位を目指そう！</span
											>
										</p>

										<div
											class="eventEnjoy_event_tab_article_box"
										>
											<div
												class="eventEnjoy_event_tab_article_item"
											>
												<div
													class="eventEnjoy_event_tab_article_item_head"
													data-type="icon"
												>
													<figure>
														<img
															src="@/views/Event/Top/src/images/enjoy/logo_world_01.png"
															alt=""
														/>
													</figure>
													<h3
														class="eventEnjoy_event_tab_article_item_head_title"
													>
														ポケモンカードゲーム
														<br />
														シティリーグ
													</h3>
												</div>

												<div
													class="eventEnjoy_event_tab_article_item_main"
												>
													<p
														class="eventEnjoy_event_tab_article_item_main_text"
													>
														真剣勝負でポイントを
														<br />
														獲得！ランキング上位を
														<br />
														目指そう！
													</p>

													<figure
														class="eventEnjoy_event_tab_article_item_main_image"
													>
														<img
															src="@/views/Event/Top/src/images/enjoy/image_world_01.png"
															alt=""
														/>
													</figure>
												</div>
											</div>
											<div
												class="eventEnjoy_event_tab_article_item"
											>
												<div
													class="eventEnjoy_event_tab_article_item_head"
													data-type="icon"
												>
													<figure>
														<img
															src="@/views/Event/Top/src/images/enjoy/logo_world_02.png"
															alt=""
														/>
													</figure>
													<h3
														class="eventEnjoy_event_tab_article_item_head_title"
													>
														チャンピオンズリーグ
													</h3>
												</div>

												<div
													class="eventEnjoy_event_tab_article_item_main"
												>
													<p
														class="eventEnjoy_event_tab_article_item_main_text"
													>
														世界大会にもつながる、
														<br />
														全国の各都市で開催される大型公式大会だ！
													</p>
													<figure
														class="eventEnjoy_event_tab_article_item_main_image"
													>
														<img
															src="@/views/Event/Top/src/images/enjoy/image_world_02.png"
															alt=""
														/>
													</figure>
												</div>
											</div>
										</div>
										<div
											class="eventEnjoy_event_tab_article_item_more"
										>
											<a
												href="https://www.pokemon-card.com/event/type/"
												class="eventTop_linkBtn"
												>イベント一覧はこちら</a
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section class="eventEnjoy_search">
						<div class="eventTop_inner">
							<h2 class="eventTop_section_title">
								<img
									src="@/views/Event/Top/src/images/enjoy/title_search.png"
									alt="イベントを探してみよう！"
								/>
							</h2>
							<p class="eventTop_section_lead">
								<span>きみの家の近くで開催される</span
								><span>イベントを見つけてみよう！</span>
							</p>

							<div class="eventEnjoy_search_more">
                                <router-link
                                    :to="{name: 'EventSearch'}"
                                    class="eventTop_linkBtn"
                                >イベントを探す</router-link>
							</div>

							<div class="eventEnjoy_search_floating">
								<img
									class="eventEnjoy_search_floating_item"
									data-type="card_l"
									src="@/views/Event/Top/src/images/enjoy/image_search_card-l.png"
									alt=""
								/>
								<img
									data-type="coin"
									class="eventEnjoy_search_floating_item"
									src="@/views/Event/Top/src/images/enjoy/image_search_coin.png"
									alt=""
								/>
								<img
									data-type="water"
									class="eventEnjoy_search_floating_item"
									src="@/views/Event/Top/src/images/enjoy/image_search_water.png"
									alt=""
								/>
								<img
									data-type="fire"
									class="eventEnjoy_search_floating_item"
									src="@/views/Event/Top/src/images/enjoy/image_search_fire.png"
									alt=""
								/>
								<img
									data-type="card_r"
									class="eventEnjoy_search_floating_item"
									src="@/views/Event/Top/src/images/enjoy/image_search_card-r.png"
									alt=""
								/>
								<img
									data-type="50"
									class="eventEnjoy_search_floating_item"
									src="@/views/Event/Top/src/images/enjoy/image_search_50.png"
									alt=""
								/>
								<img
									data-type="10"
									class="eventEnjoy_search_floating_item"
									src="@/views/Event/Top/src/images/enjoy/image_search_10.png"
									alt=""
								/>
								<img
									data-type="100"
									class="eventEnjoy_search_floating_item"
									src="@/views/Event/Top/src/images/enjoy/image_search_100.png"
									alt=""
								/>
							</div>
						</div>

						<div class="eventEnjoy_search_bg">
							<picture>
								<source
									srcset="
										@/views/Event/Top/src/images/enjoy/image_search_playmat_sp.png
									"
									media="(max-width: 768px)"
								/>
								<img
									class="eventEnjoy_search_bg_image"
									src="@/views/Event/Top/src/images/enjoy/image_search_playmat.png"
									alt=""
								/>
							</picture>
						</div>
					</section>

					<section class="eventTop_faq">
						<div class="eventTop_inner">
							<h2 class="eventTop_section_title">
								<img
									src="@/views/Event/Top/src/images/enjoy/title_faq.png"
									alt="よくある質問"
								/>
							</h2>

							<ul class="eventTop_faq_list">
								<li class="eventTop_faq_item" data-open="false">
									<div class="eventTop_faq_item_inner">
										<h3 class="eventTop_faq_item_title">
											<span>すべてのイベントは</span
											><span
												>事前申込みが必要ですか？</span
											>
										</h3>

										<p class="eventTop_faq_item_text">
											イベントの検索結果で、「事前応募・予約あり」となっているイベントについては、事前に予約が必要となります。そのようなイベントについては、「<router-link
                                                :to="{name: 'MailRegister'}"
                                            >ポケモンカードゲーム
                                            プレイヤーズクラブ</router-link>」に登録の上、ご予約ください。
										</p>

										<figure
											class="eventTop_faq_item_image"
											style="--image-width: 500"
										>
											<figcaption
												class="eventTop_faq_item_image_ballon"
												style="--ballon-width: 246"
											>
												<img
													src="@/views/Event/Top/src/images/enjoy/image_faq01-balloon.png"
													alt="予約なしで参加できるイベントも!"
												/>
											</figcaption>
											<img
												src="@/views/Event/Top/src/images/enjoy/image_faq01.jpg"
												alt=""
											/>
										</figure>

										<p class="eventTop_faq_item_text">
											一部ポケモンカードゲーム教室や、カードショップのイベントなど、事前申込みの必要がなく、お店に来店することで先着順に参加できるものもございます。
										</p>

										<p class="eventTop_faq_item_text">
											ぜひイベント情報をチェックして気軽に参加してみてくださいね。
										</p>

										<div class="eventTop_faq_item_more">
                                            <router-link
                                                :to="{name: 'MailRegister'}"
                                                class="eventTop_linkBtn"
                                            >新規登録する</router-link>
										</div>
									</div>

									<button class="eventTop_faq_item_opener">
										<span
											class="eventTop_faq_item_opener_icon"
										></span>
										<span
											class="eventTop_faq_item_opener_text"
											>くわしく見る</span
										>
									</button>
								</li>

								<li class="eventTop_faq_item" data-open="false">
									<div class="eventTop_faq_item_inner">
										<h3 class="eventTop_faq_item_title">
											<span
												>プレイヤーズクラブの利用に</span
											><span>費用はかかりますか？</span>
										</h3>

										<p class="eventTop_faq_item_text">
											「ポケモンカードゲーム
											プレイヤーズクラブ」は「ポケモンカードゲーム」のイベント情報の閲覧、イベントエントリーができる会員制サイトで、<strong>無料でアカウントを作成、ご利用いただくことが可能</strong>です。
											<br />
											イベントへの応募のほか、マイデッキの登録や、ポイント付与対象の大会に参加して獲得した「チャンピオンシップポイント」を貯めることなどができるようになります。
										</p>

										<figure
											class="eventTop_faq_item_image"
											style="--image-width: 525"
										>
											<picture>
												<source
													srcset="
														@/views/Event/Top/src/images/enjoy/image_faq02_sp.png
													"
													media="(max-width: 768px)"
													width="270"
												/>
												<img
													src="@/views/Event/Top/src/images/enjoy/image_faq02.png"
													alt=""
												/>
											</picture>
										</figure>

										<p class="eventTop_faq_item_note">
											接続に関わる通信費等や、イベント参加のための交通費、有料イベントの場合の費用などはお客様のご負担となりますので、予めご了承ください。
										</p>

										<div class="eventTop_faq_item_more">
                                            <router-link
                                                :to="{name: 'Mypage'}"
                                                class="eventTop_linkBtn"
                                            ><span
                                                >ポケモンカードゲーム</span
                                                >
                                                <span
                                                >プレイヤーズクラブ</span
                                            ></router-link>
										</div>
									</div>

									<button class="eventTop_faq_item_opener">
										<span
											class="eventTop_faq_item_opener_icon"
										></span>
										<span
											class="eventTop_faq_item_opener_text"
											>くわしく見る</span
										>
									</button>
								</li>

								<li class="eventTop_faq_item" data-open="false">
									<div class="eventTop_faq_item_inner">
										<h3 class="eventTop_faq_item_title">
											<span
												>イベントの参加に必要なものは</span
											><span>なんでしょう？</span>
										</h3>

										<figure
											class="eventTop_faq_item_image"
											style="--image-width: 500"
										>
											<img
												src="@/views/Event/Top/src/images/enjoy/image_faq03-1.jpg"
												alt=""
											/>
										</figure>

										<p class="eventTop_faq_item_text">
											イベントへ参加するために、デッキやダメカン、ポケモンコインなど、対戦に使うものを用意しましょう。<br />
											初心者向けのイベントなど、<strong>デッキがなくても参加できるイベント</strong>もございます。くわしくは各イベント詳細ページをご確認ください。
										</p>

										<div
											class="eventTop_faq_item_reccomend"
										>
											<figure
												class="eventTop_faq_item_reccomend_image"
											>
												<picture>
													<source
														srcset="
															@/views/Event/Top/src/images/enjoy/image_faq03-2_sp.png
														"
													/>
													<img
														src="@/views/Event/Top/src/images/enjoy/image_faq03-2.png"
														alt=""
													/>
												</picture>
											</figure>
											<p
												class="eventTop_faq_item_reccomend_text"
											>
												構築デッキには、すぐに遊べるよう６０枚のカードデッキになっていてダメカンやポケモンコイン、プレイマットがセットになっているものもあります。
											</p>
										</div>

										<div class="eventTop_faq_item_more">
											<div
												class="eventTop_faq_item_more_box"
											>
												<a
													href="https://www.pokemon-card.com/products/index.html?productType=construction"
													class="eventTop_linkBtn"
													>便利な構築デッキ商品はこちら</a
												>
												<a
													href="https://www.pokemon-card.com/rules/howtoplay/basic_rules/02.html"
													class="eventTop_linkBtn"
													>対戦で使うもの</a
												>
											</div>
										</div>
									</div>

									<button class="eventTop_faq_item_opener">
										<span
											class="eventTop_faq_item_opener_icon"
										></span>
										<span
											class="eventTop_faq_item_opener_text"
										>
											くわしく見る
										</span>
									</button>
								</li>
							</ul>
						</div>
					</section>

					<section class="eventTop_players">
						<div class="eventTop_inner">
							<h2 class="eventTop_section_title">
								<img
									src="@/views/Event/Top/src/images/common/title_players.png"
									alt="プレイヤー名鑑"
								/>
							</h2>
							<p class="eventTop_section_lead">
								プレイヤーの数だけ楽しみ方があるのが<br
									data-sp
								/>ポケモンカードゲーム。
								<br />
								さあ一緒に楽しもう！
							</p>

							<div class="eventTop_players_more">
								<a
									href="https://www.pokemon-card.com/event/topplayers/"
									class="eventTop_linkBtn"
									>活躍した選手たちを見る</a
								>
							</div>
						</div>
					</section>

					<section class="eventTop_news">
						<div class="eventTop_inner">
							<h2 class="eventTop_section_title">
								<img
									src="@/views/Event/Top/src/images/common/title_news.png"
									alt="プレイヤー名鑑"
								/>
							</h2>

							<ul class="eventTop_news_list">
                                <template v-if="news.length">
                                    <li
                                        v-for="(information, i) in news"
                                        :key="`news-${i}`"
                                    >
                                        <news-list-item
                                            :information="information"
                                        />
                                    </li>
                                </template>
							</ul>

							<div class="eventTop_news_more">
                                <router-link
                                    :to="{name: 'NewsList'}"
                                    class="eventTop_linkBtn"
                                >お知らせ一覧へ</router-link>
							</div>
						</div>
					</section>
				</div>

				<!-- チャレンジ -->
				<div
					class="eventTop_contents eventChallenge"
					data-type="challenge"
				>
					<div class="eventChallenge_firstWrap">
						<!-- 開催中のチャンピオンシップ -->
						<section class="eventChallenge_championship">
							<div class="eventChallenge_championship_inner">
								<h2 class="eventChallenge_championship_title">
									<img
										src="@/views/Event/Top/src/images/challenge/title_championship.png"
										alt="チャンピオンシップシリーズ 2025年シーズンを開催中！"
									/>
								</h2>

								<figure
									class="eventChallenge_championship_logo"
								>
									<img
										src="@/views/Event/Top/src/images/challenge/image_logo2025.png"
										alt=""
									/>
								</figure>

								<div class="eventChallenge_championship_detail">
									<button
										data-micromodal-trigger="modal_challenge_schedule"
										class="eventTop_linkBtn"
									>
										2025年シーズンのスケジュール
									</button>
								</div>
							</div>
						</section>
						<section class="eventChallenge_event">
							<div class="eventTop_inner">
								<article class="eventChallenge_event_search">
									<h3
										class="eventChallenge_event_search_title"
									>
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/challenge/title_search_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/challenge/title_search.png"
												alt="近くのイベントを探してみよう"
											/>
										</picture>
									</h3>

									<nav
										class="eventChallenge_event_search_nav"
									>
										<ul
											class="eventChallenge_event_search_nav_list"
										>
											<li
												class="eventChallenge_event_search_nav_item"
											>
                                                <router-link
                                                    :to="{
                                                        name: 'EventSearch',
                                                        query: {
                                                            'keyword': 'ジムバトル',
                                                            'offset': 0,
                                                            'order': 1
                                                        }
                                                    }"
                                                    class="eventChallenge_event_search_nav_btn"
                                                >
													<picture>
														<source
															srcset="
																@/views/Event/Top/src/images/challenge/btn_search-01_sp.png
															"
															media="(max-width: 768px)"
														/>
														<img
															src="@/views/Event/Top/src/images/challenge/btn_search-01.png"
															alt="ジムバトル 検索する"
														/>
													</picture>
												</router-link>
											</li>
											<li
												class="eventChallenge_event_search_nav_item"
											>
                                                <router-link
                                                    :to="{
                                                        name: 'EventSearch',
                                                        query: {
                                                            'keyword': '新弾バトル',
                                                            'offset': 0,
                                                            'order': 1
                                                        }
                                                    }"
                                                    class="eventChallenge_event_search_nav_btn"
                                                >
													<picture>
														<source
															srcset="
																@/views/Event/Top/src/images/challenge/btn_search-02_sp.png
															"
															media="(max-width: 768px)"
														/>
														<img
															src="@/views/Event/Top/src/images/challenge/btn_search-02.png"
															alt="新弾バトル 検索する"
														/>
													</picture>
												</router-link>
											</li>
											<li
												class="eventChallenge_event_search_nav_item"
											>
                                                <router-link
                                                    :to="{
                                                        name: 'EventSearch',
                                                        query: {
                                                            'keyword': 'トレーナーズリーグ',
                                                            'offset': 0,
                                                            'order': 1
                                                        }
                                                    }"
                                                    class="eventChallenge_event_search_nav_btn"
                                                >
													<picture>
														<source
															srcset="
																@/views/Event/Top/src/images/challenge/btn_search-03_sp.png
															"
															media="(max-width: 768px)"
														/>
														<img
															src="@/views/Event/Top/src/images/challenge/btn_search-03.png"
															alt="トレーナーズリーグ 検索する"
														/>
													</picture>
												</router-link>
											</li>
										</ul>
										<ul
											class="eventChallenge_event_search_nav_list"
											data-type="howto"
										>
											<li
												class="eventChallenge_event_search_nav_item"
											>
												<button
													class="eventChallenge_event_search_nav_btn"
													data-micromodal-trigger="modal_challenge_point"
												>
													<picture>
														<source
															srcset="
																@/views/Event/Top/src/images/challenge/btn_search-04_sp.png
															"
															media="(max-width: 768px)"
														/>
														<img
															src="@/views/Event/Top/src/images/challenge/btn_search-04.png"
															alt="チャンピオンシップ ポイントを貯めよう"
														/>
													</picture>
												</button>
											</li>
											<li
												class="eventChallenge_event_search_nav_item"
											>
												<button
													class="eventChallenge_event_search_nav_btn"
													data-micromodal-trigger="modal_challenge_howto"
												>
													<picture>
														<source
															srcset="
																@/views/Event/Top/src/images/challenge/btn_search-05_sp.png
															"
															media="(max-width: 768px)"
														/>
														<img
															src="@/views/Event/Top/src/images/challenge/btn_search-05.png"
															alt="プレイヤーズクラブの使いかた"
														/>
													</picture>
												</button>
											</li>
											<li
												class="eventChallenge_event_search_nav_item"
											>
												<a
													href="https://www.pokemon-card.com/event/wcs/"
													class="eventChallenge_event_search_nav_btn"
												>
													<picture>
														<source
															srcset="
																@/views/Event/Top/src/images/challenge/btn_search-06_sp.png
															"
															media="(max-width: 768px)"
														/>
														<img
															src="@/views/Event/Top/src/images/challenge/btn_search-06.png"
															alt="ワールドチャンピオンシップスへの道 プレイヤーランキングを上げて、世界大会の出場権を目指そう！ くわしく見る"
														/>
													</picture>
												</a>
											</li>
										</ul>
									</nav>
								</article>
							</div>
						</section>
						<section class="eventChallenge_ranking">
							<div class="eventTop_inner">
								<h2 class="eventTop_section_title">
									<img
										src="@/views/Event/Top/src/images/challenge/title_ranking.png"
										alt="プレイヤーランキング"
									/>
								</h2>
								<p class="eventTop_section_lead">
									チャンピオンシップシリーズの<br />
									年齢区分ごとのランキングを掲載！
								</p>

								<ul class="eventChallenge_ranking_list">
									<li class="eventChallenge_ranking_item">
                                        <router-link
                                            :to="{
                                                name: 'PlayerRanking',
                                                hash: '#master'
                                            }"
                                            class="eventChallenge_ranking_item_inner"
                                        >
											<h3
												class="eventChallenge_ranking_item_title"
											>
												マスター
											</h3>

											<p
												class="eventChallenge_ranking_item_detail"
											>
												2007年<br />
												4月1日以前生まれ
											</p>
										</router-link>
									</li>
									<li class="eventChallenge_ranking_item">
                                        <router-link
                                            :to="{
                                                name: 'PlayerRanking',
                                                hash: '#senior'
                                            }"
                                            class="eventChallenge_ranking_item_inner"
                                        >
											<h3
												class="eventChallenge_ranking_item_title"
											>
												シニア
											</h3>

											<p
												class="eventChallenge_ranking_item_detail"
											>
												2007年4月2日〜
												<br />
												2013年4月1日生まれ
											</p>
										</router-link>
									</li>
									<li class="eventChallenge_ranking_item">
                                        <router-link
                                            :to="{
                                                name: 'PlayerRanking',
                                                hash: '#junior'
                                            }"
                                            class="eventChallenge_ranking_item_inner"
                                        >
											<h3
												class="eventChallenge_ranking_item_title"
											>
												ジュニア
											</h3>

											<p
												class="eventChallenge_ranking_item_detail"
											>
												2013年
												<br />
												4月2日以降生まれ
											</p>
										</router-link>
									</li>
								</ul>
							</div>
						</section>

						<div class="eventChallenge_firstWrap_floating">
							<div class="eventTop_inner">
								<img
									class="eventChallenge_firstWrap_floating_item"
									src="@/views/Event/Top/src/images/challenge/image_results_coin.png"
									alt=""
								/>
								<img
									class="eventChallenge_firstWrap_floating_item"
									src="@/views/Event/Top/src/images/challenge/image_character.png"
									alt=""
								/>
							</div>
						</div>
					</div>
					<section class="eventChallenge_result">
						<div class="eventTop_inner">
							<h2 class="eventTop_section_title">
								<img
									src="@/views/Event/Top/src/images/challenge/title_result.png"
									alt="イベント結果発表！"
								/>
							</h2>

							<div class="eventChallenge_result_list">
                                <template v-if="event.length">
                                    <event-result-list-item
                                        v-for="(singleEvent, i) in event"
                                        :key="`eventResult-${i}`"
                                        :eventData="singleEvent"
                                    />
                                </template>
								<!-- <a href="" class="eventListItem"
									><div class="left">
										<div class="eventDate">
											<div class="date sun">
												<span class="day">06/02</span
												><span class="week"
													>（日）</span
												>
											</div>
											<span class="time"
												>09:00 ～ 19:30</span
											><span class="pref">神奈川県</span>
										</div>
									</div>
									<div class="right">
										<div class="title">
											ポケモンジャパンチャンピオンシップス2024
											カードゲーム部門 シニアリーグ Day2
										</div>
										<div class="league">◆ シニア</div>
										<div class="detail">
											<span class="regulation"
												>スタンダード</span
											><span class="entryFee">
												/ 参加費 無料</span
											><span class="capacity">
												/ 定員 32人</span
											>
										</div>
										<div class="address-box">
											<div class="shop">
												株式会社ポケモン
											</div>
											<div class="address">
												<span class="building"
													>横浜市西区みなとみらい１丁目１−１
												</span>
											</div>
											<div class="address">
												<span class=""
													>パシフィコ横浜&emsp;展示ホールB・C・D</span
												>
											</div>
										</div>
									</div>
								</a>
								<a href="" class="eventListItem"
									><div class="left">
										<div class="eventDate">
											<div class="date sun">
												<span class="day">06/02</span
												><span class="week"
													>（日）</span
												>
											</div>
											<span class="time"
												>09:00 ～ 19:30</span
											><span class="pref">神奈川県</span>
										</div>
									</div>
									<div class="right">
										<div class="title">
											ポケモンジャパンチャンピオンシップス2024
											カードゲーム部門 シニアリーグ Day2
										</div>
										<div class="league">◆ シニア</div>
										<div class="detail">
											<span class="regulation"
												>スタンダード</span
											><span class="entryFee">
												/ 参加費 無料</span
											><span class="capacity">
												/ 定員 32人</span
											>
										</div>
										<div class="address-box">
											<div class="shop">
												株式会社ポケモン
											</div>
											<div class="address">
												<span class="building"
													>横浜市西区みなとみらい１丁目１−１
												</span>
											</div>
											<div class="address">
												<span class=""
													>パシフィコ横浜&emsp;展示ホールB・C・D</span
												>
											</div>
										</div>
									</div>
								</a> -->
							</div>

							<div class="eventChallenge_result_more">
                                <router-link
                                    :to="{name: 'EventResultList'}"
                                    class="eventTop_linkBtn"
                                >結果発表イベント一覧へ</router-link>
							</div>
						</div>
					</section>

					<section class="eventTop_faq">
						<div class="eventTop_inner">
							<h2 class="eventTop_section_title">
								<img
									src="@/views/Event/Top/src/images/challenge/title_faq.png"
									alt="よくある質問"
								/>
							</h2>

							<ul class="eventTop_faq_list">
								<li class="eventTop_faq_item" data-open="false">
									<div class="eventTop_faq_item_inner">
										<h3 class="eventTop_faq_item_title">
											<span
												>チャンピオンシップポイントとは</span
											><span>なんですか？</span>
										</h3>

										<p class="eventTop_faq_item_text">
											チャンピオンシップポイントは、ポイント付与対象の大会で<strong>一定の成績を収めると入手できるポイント</strong>です。
										</p>

										<figure
											class="eventTop_faq_item_image"
											style="--image-width: 418"
										>
											<picture>
												<source
													srcset="
														@/views/Event/Top/src/images/challenge/image_faq01_sp.png
													"
													media="(max-width: 768px)"
												/>
												<img
													src="@/views/Event/Top/src/images/challenge/image_faq01.png"
													alt=""
												/>
											</picture>
										</figure>

										<p class="eventTop_faq_item_note">
											チャンピオンシップポイントの有効期間は、毎年開催されるチャンピオンシップシリーズ開催期間である1年間となります。<br />
											この期間中、より多くの大会に参加してポイントを貯めることで、賞品やポケモンワールドチャンピオンシップスへの出場権利を入手することができます。
										</p>

										<div class="eventTop_faq_item_more">
                                            <router-link
                                                :to="{name: 'Mypage'}"
                                                class="eventTop_linkBtn"
                                            ><span
                                                >ポケモンカードゲーム</span
                                                >
                                                <span
                                                >プレイヤーズクラブ</span
                                            ></router-link>
										</div>
									</div>

									<button class="eventTop_faq_item_opener">
										<span
											class="eventTop_faq_item_opener_icon"
										></span>
										<span
											class="eventTop_faq_item_opener_text"
											>くわしく見る</span
										>
									</button>
								</li>

								<li class="eventTop_faq_item" data-open="false">
									<div class="eventTop_faq_item_inner">
										<h3 class="eventTop_faq_item_title">
											<span
												>ポケモンカードの公式大会の</span
											><span>種類が知りたい</span>
										</h3>

										<p class="eventTop_faq_item_lead">
											<span
												>ポケモンカードゲームの主な大会は、</span
											>
											<span
												>下記のような区分に分かれています。</span
											>
										</p>

										<div class="eventTop_faq_item_gym">
											<figure
												class="eventTop_faq_item_gym_logo"
											>
												<img
													src="@/views/Event/Top/src/images/challenge/icon_faq02-1.png"
													alt=""
												/>
											</figure>

											<p
												class="eventTop_faq_item_gym_text"
											>
												全国のポケモンカードジムで開催している対戦イベントです。ジムバトル以外も<strong>さまざまなイベントが全国で開催</strong>されています。
											</p>
										</div>

										<article
											class="eventTop_faq_item_championship"
										>
											<h4
												class="eventTop_faq_item_championship_title"
											>
												<span>チャンピオンシップ</span
												><span>シリーズ</span>
											</h4>

											<p
												class="eventTop_faq_item_championship_lead"
											>
												チャンピオンシップポイント(CSP)や、ポケモンワールドチャンピオンシップス(WCS)への出場権利を獲得できる対象のイベントです。
											</p>

											<ul
												class="eventTop_faq_item_championship_list"
											>
												<li
													class="eventTop_faq_item_championship_item"
												>
													<figure
														class="eventTop_faq_item_championship_item_logo"
													>
														<img
															src="@/views/Event/Top/src/images/challenge/icon_faq02-2.png"
															alt=""
														/>
													</figure>
													<div
														class="eventTop_faq_item_championship_item_detail"
													>
														<h5
															class="eventTop_faq_item_championship_item_title"
														>
															トレーナーズリーグ
														</h5>
														<p
															class="eventTop_faq_item_championship_item_text"
														>
															全国のポケモンカードジムで開催。成績上位者はCSPを獲得。
														</p>
													</div>
												</li>
												<li
													class="eventTop_faq_item_championship_item"
												>
													<figure
														class="eventTop_faq_item_championship_item_logo"
													>
														<img
															src="@/views/Event/Top/src/images/challenge/icon_faq02-3-1.png"
															alt=""
														/>
													</figure>
													<div
														class="eventTop_faq_item_championship_item_detail"
													>
														<h5
															class="eventTop_faq_item_championship_item_title"
														>
															シティリーグ
														</h5>
														<p
															class="eventTop_faq_item_championship_item_text"
														>
															ポケモンカードジム、ポケモンセンターなどで、年に４シーズン開催される中規模大会。
														</p>
													</div>
												</li>
												<li
													class="eventTop_faq_item_championship_item"
												>
													<figure
														class="eventTop_faq_item_championship_item_logo"
													>
														<img
															src="@/views/Event/Top/src/images/challenge/icon_faq02-3.png"
															alt=""
														/>
													</figure>
													<div
														class="eventTop_faq_item_championship_item_detail"
													>
														<h5
															class="eventTop_faq_item_championship_item_title"
														>
															チャンピオンズリーグ
														</h5>
														<p
															class="eventTop_faq_item_championship_item_text"
														>
															年に複数回、全国主要都市で開催される大型大会。上位入賞で世界大会やジャパンチャンピオンシップス参加権を獲得。
														</p>
													</div>
												</li>
												<li
													class="eventTop_faq_item_championship_item"
												>
													<figure
														class="eventTop_faq_item_championship_item_logo"
													>
														<img
															src="@/views/Event/Top/src/images/challenge/icon_faq02-4.png"
															alt=""
														/>
													</figure>
													<div
														class="eventTop_faq_item_championship_item_detail"
													>
														<h5
															class="eventTop_faq_item_championship_item_title"
														>
															<span
																>ジャパンチャンピオン</span
															><span
																>シップス</span
															>
														</h5>
														<p
															class="eventTop_faq_item_championship_item_text"
														>
															1年に1回開催されるポケモンの日本一を決める大会。上位入賞で世界大会の参加権を獲得。
														</p>
													</div>
												</li>
											</ul>
										</article>

										<div class="eventTop_faq_item_more">
											<a
												href="https://www.pokemon-card.com/event/wcs/"
												class="eventTop_linkBtn"
												><span
													>ポケモンワールドチャンピオン</span
												><span>シップスへの道</span></a
											>
										</div>
									</div>

									<button class="eventTop_faq_item_opener">
										<span
											class="eventTop_faq_item_opener_icon"
										></span>
										<span
											class="eventTop_faq_item_opener_text"
										>
											くわしく見る
										</span>
									</button>
								</li>

								<li class="eventTop_faq_item" data-open="false">
									<div class="eventTop_faq_item_inner">
										<h3 class="eventTop_faq_item_title">
											<span>ポケカ公式大会の、</span
											><span
												>年齢ごとのイベント区分が知りたい</span
											>
										</h3>

										<p class="eventTop_faq_item_text">
											一部のポケモンカードゲームの公認・公式大会では、年齢ごとに、「マスターリーグ」「シニアリーグ」「ジュニアリーグ」に分かれた対戦を行います。また年齢に関係なく参加できる「オープンリーグ」の大会もあります。
										</p>

										<ul class="eventTop_faq_item_league">
											<li
												class="eventTop_faq_item_league_item"
												data-type="master"
											>
												<div
													class="eventTop_faq_item_league_item_inner"
												>
													<h4
														class="eventTop_faq_item_league_item_inner_title"
													>
														マスター
													</h4>
													<p
														class="eventTop_faq_item_league_item_inner_text"
													>
														2007年<br />
														4月1日以前生まれ
													</p>
												</div>
												<p
													class="eventTop_faq_item_league_item_text"
												>
													<i
														class="eventTop_faq_item_league_item_text_icon"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="21"
															height="21"
															viewBox="0 0 21 21"
															fill="none"
														>
															<path
																d="M4.67869 17.1572C4.67869 17.1572 3.51203 17.1601 3.50909 15.9935C3.50616 14.8268 4.66402 11.3239 10.4973 11.3092C16.3306 11.2945 17.5061 14.7916 17.509 15.9582C17.512 17.1249 16.3453 17.1278 16.3453 17.1278L4.67869 17.1572ZM10.4944 10.1425C11.4227 10.1402 12.312 9.7692 12.9667 9.11117C13.6214 8.45315 13.9879 7.56198 13.9856 6.63373C13.9832 5.70547 13.6123 4.81617 12.9542 4.16144C12.2962 3.50672 11.405 3.14021 10.4768 3.14255C9.54853 3.14488 8.65922 3.51587 8.0045 4.17389C7.34977 4.83192 6.98327 5.72309 6.9856 6.65134C6.98794 7.57959 7.35892 8.4689 8.01695 9.12363C8.67498 9.77835 9.56614 10.1449 10.4944 10.1425Z"
																fill="#6779DB"
															/>
														</svg>
													</i>
													大学生以上
												</p>
											</li>
											<li
												class="eventTop_faq_item_league_item"
												data-type="senior"
											>
												<div
													class="eventTop_faq_item_league_item_inner"
												>
													<h4
														class="eventTop_faq_item_league_item_inner_title"
													>
														シニア
													</h4>
													<p
														class="eventTop_faq_item_league_item_inner_text"
													>
														2007年4月2日〜<br />
														2013年4月1日生まれ
													</p>
												</div>
												<p
													class="eventTop_faq_item_league_item_text"
												>
													<i
														class="eventTop_faq_item_league_item_text_icon"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="21"
															height="21"
															viewBox="0 0 21 21"
															fill="none"
														>
															<path
																d="M4.67869 17.1572C4.67869 17.1572 3.51203 17.1601 3.50909 15.9935C3.50616 14.8268 4.66402 11.3239 10.4973 11.3092C16.3306 11.2945 17.5061 14.7916 17.509 15.9582C17.512 17.1249 16.3453 17.1278 16.3453 17.1278L4.67869 17.1572ZM10.4944 10.1425C11.4227 10.1402 12.312 9.7692 12.9667 9.11117C13.6214 8.45315 13.9879 7.56198 13.9856 6.63373C13.9832 5.70547 13.6123 4.81617 12.9542 4.16144C12.2962 3.50672 11.405 3.14021 10.4768 3.14255C9.54853 3.14488 8.65922 3.51587 8.0045 4.17389C7.34977 4.83192 6.98327 5.72309 6.9856 6.65134C6.98794 7.57959 7.35892 8.4689 8.01695 9.12363C8.67498 9.77835 9.56614 10.1449 10.4944 10.1425Z"
																fill="#4881E2"
															/>
														</svg>
													</i>
													中学生・高校生
												</p>
											</li>
											<li
												class="eventTop_faq_item_league_item"
												data-type="junior"
											>
												<div
													class="eventTop_faq_item_league_item_inner"
												>
													<h4
														class="eventTop_faq_item_league_item_inner_title"
													>
														ジュニア
													</h4>
													<p
														class="eventTop_faq_item_league_item_inner_text"
													>
														2013年
														<br />
														4月2日以降生まれ
													</p>
												</div>
												<p
													class="eventTop_faq_item_league_item_text"
												>
													<i
														class="eventTop_faq_item_league_item_text_icon"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="21"
															height="21"
															viewBox="0 0 21 21"
															fill="none"
														>
															<path
																d="M4.67869 17.1572C4.67869 17.1572 3.51203 17.1601 3.50909 15.9935C3.50616 14.8268 4.66402 11.3239 10.4973 11.3092C16.3306 11.2945 17.5061 14.7916 17.509 15.9582C17.512 17.1249 16.3453 17.1278 16.3453 17.1278L4.67869 17.1572ZM10.4944 10.1425C11.4227 10.1402 12.312 9.7692 12.9667 9.11117C13.6214 8.45315 13.9879 7.56198 13.9856 6.63373C13.9832 5.70547 13.6123 4.81617 12.9542 4.16144C12.2962 3.50672 11.405 3.14021 10.4768 3.14255C9.54853 3.14488 8.65922 3.51587 8.0045 4.17389C7.34977 4.83192 6.98327 5.72309 6.9856 6.65134C6.98794 7.57959 7.35892 8.4689 8.01695 9.12363C8.67498 9.77835 9.56614 10.1449 10.4944 10.1425Z"
																fill="#0B82EC"
															/>
														</svg>
													</i>
													主に小学生
												</p>
											</li>
										</ul>

										<p class="eventTop_faq_item_note">
											「ジュニアリーグ」は主に小学生、「シニアリーグ」は中学生・高校生の方に参加いただきやすいように設計されています。
										</p>

										<p class="eventTop_faq_item_text">
											各区分ごとに全国各地のポケモンカードジムで行われるトレーナーズリーグ、シティリーグの成績上位者に、チャンピオンシップポイントが付与されます。
										</p>
									</div>

									<button class="eventTop_faq_item_opener">
										<span
											class="eventTop_faq_item_opener_icon"
										></span>
										<span
											class="eventTop_faq_item_opener_text"
											>くわしく見る</span
										>
									</button>
								</li>
							</ul>
						</div>
					</section>

					<section class="eventTop_players">
						<div class="eventTop_inner">
							<h2 class="eventTop_section_title">
								<img
									src="@/views/Event/Top/src/images/common/title_players.png"
									alt="プレイヤー名鑑"
								/>
							</h2>
							<p class="eventTop_section_lead">
								プレイヤーの数だけ楽しみ方があるのが<br
									data-sp
								/>ポケモンカードゲーム。
								<br />
								さあ一緒に楽しもう！
							</p>

							<div class="eventTop_players_more">
								<a
									href="https://www.pokemon-card.com/event/topplayers/"
									class="eventTop_linkBtn"
									>活躍した選手たちを見る</a
								>
							</div>
						</div>
					</section>

					<section class="eventTop_news">
						<div class="eventTop_inner">
							<h2 class="eventTop_section_title">
								<img
									src="@/views/Event/Top/src/images/common/title_news.png"
									alt="お知らせ"
								/>
							</h2>

							<ul class="eventTop_news_list">
                                <template v-if="news.length">
                                    <li
                                        v-for="(information, i) in news"
                                        :key="`news-${i}`"
                                    >
                                        <news-list-item
                                            :information="information"
                                        />
                                    </li>
                                </template>
							</ul>

							<div class="eventTop_news_more">
                                <router-link
                                    :to="{name: 'NewsList'}"
                                    class="eventTop_linkBtn"
                                >お知らせ一覧へ</router-link>
							</div>
						</div>
					</section>
				</div>

				<!-- YouTubeモーダル -->
				<div
					id="modal_youtube"
					class="eventTop_modal"
					aria-hidden="true"
				>
					<div class="eventTop_modal_overlay" data-micromodal-close>
						<div class="eventTop_modal_cover"></div>
						<div
							class="eventTop_modal_youtube"
							role="dialog"
							aria-modal="true"
							aria-labelledby="modal-1-title"
						>
							<div class="eventTop_modal_youtube_inner"></div>
						</div>

						<button
							class="eventTop_modal_close"
							data-micromodal-close
						>
							<img
								src="@/views/Event/Top/src/images/common/btn_modal_close.png"
								alt="閉じる"
							/>
						</button>
					</div>
				</div>

				<!-- enjoy用モーダル -->
				<div
					id="modal_enjoy_howto"
					class="eventTop_modal"
					aria-hidden="true"
				>
					<div class="eventTop_modal_overlay" data-micromodal-close>
						<div class="eventTop_modal_cover"></div>
						<div
							class="eventTop_modal_dialog"
							role="dialog"
							aria-modal="true"
							aria-labelledby="イベントの楽しみ方"
						>
							<div class="eventTop_modal_dialog_inner">
								<div class="eventTop_modal_spClose">
									<button
										data-micromodal-close
										class="eventTop_modal_spClose_btn"
									></button>
								</div>
								<div class="eventTop_modal_inner">
									<h2 class="eventTop_modal_title">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/enjoy/popup/howto/title_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/enjoy/popup/howto/title.png"
												alt="イベントの楽しみ方"
											/>
										</picture>
									</h2>

									<p class="eventTop_modal_lead">
										はじめてでも大丈夫！<br
											data-pc
										/>自分の目的に<br
											data-sp
										/>合わせてイベントを探そう！
									</p>

									<p class="eventTop_modal_text">
										ポケモンカードゲームには、ルールを教わるイベントから、お互いに買ったデッキそのままで対戦する初心者向けのバトルイベント、自慢のマイデッキでいろんなレベルの人と対戦できるジムイベントなど、たくさんのイベントがあるよ。
									</p>

									<figure class="eventTop_modal_image">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/enjoy/popup/howto/image_illust_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/enjoy/popup/howto/image_illust.png"
												alt=""
												width="548"
											/>
										</picture>
									</figure>

									<div class="eventTop_modal_detail">
										<h4 class="eventTop_modal_detail_title">
											全国のポケモンセンター、<br />
											ポケモンカードジムでイベント開催中！
										</h4>

										<p class="eventTop_modal_detail_text">
											全国のポケモンセンターや全国に1,500店舗以上展開するポケモンカードジムで、ほぼ毎日イベントを実施中！自分にあったイベントを探して、ぜひ参加してみてね！
										</p>

										<figure
											class="eventTop_modal_detail_image"
										>
											<picture>
												<source
													srcset="
														@/views/Event/Top/src/images/enjoy/popup/howto/image_illust-02_sp.jpg
													"
													media="(max-width: 768px)"
												/>
												<img
													src="@/views/Event/Top/src/images/enjoy/popup/howto/image_illust-02.jpg"
													alt=""
												/>
											</picture>
										</figure>
									</div>

									<a
										href="https://www.pokemon-card.com/event/type/"
										class="eventTop_linkBtn"
										>さらにくわしく</a
									>
								</div>
							</div>
						</div>
						<button
							class="eventTop_modal_close"
							data-micromodal-close
						>
							<img
								src="@/views/Event/Top/src/images/common/btn_modal_close.png"
								alt="閉じる"
							/>
						</button>
					</div>
				</div>

				<div
					id="modal_enjoy_recommend"
					class="eventTop_modal"
					aria-hidden="true"
				>
					<div class="eventTop_modal_overlay" data-micromodal-close>
						<div class="eventTop_modal_cover"></div>
						<div
							class="eventTop_modal_dialog"
							role="dialog"
							aria-modal="true"
							aria-labelledby="はじめての方でも大丈夫！楽しいイベントが盛り沢山！"
						>
							<div class="eventTop_modal_dialog_inner">
								<div class="eventTop_modal_spClose">
									<button
										data-micromodal-close
										class="eventTop_modal_spClose_btn"
									></button>
								</div>
								<div class="eventTop_modal_inner">
									<h2 class="eventTop_modal_title">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/enjoy/popup/recommend/title_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/enjoy/popup/recommend/title.png"
												alt="はじめての方でも大丈夫！楽しいイベントが盛り沢山！"
											/>
										</picture>
									</h2>

									<p class="eventTop_modal_lead">
										<img
											src="@/views/Event/Top/src/images/enjoy/popup/recommend/lead.png"
											alt="まずルールを教わるなら… 「ポケモンカードゲーム教室」"
										/>
									</p>

									<p class="eventTop_modal_text">
										これからポケモンカードゲームをはじめたいキミにぴったり！楽しくポケモンカードゲームを体験できるよ！家族やお友だちといっしょに参加しよう！
									</p>

									<div class="eventTop_modal_detail">
										<figure
											class="eventTop_modal_detail_image"
										>
											<picture>
												<source
													srcset="
														@/views/Event/Top/src/images/enjoy/popup/recommend/image_illust_sp.png
													"
													media="(max-width: 768px)"
												/>
												<img
													src="@/views/Event/Top/src/images/enjoy/popup/recommend/image_illust.png"
													alt=""
												/>
											</picture>
										</figure>
									</div>

									<p class="eventTop_modal_text">
										ルールを覚えたら、購入したデッキそのままでもバトルできる「デッキそのままバトル」も開催しているよ！
									</p>

									<a
										href="https://www.pokemon-card.com/event/pokemon_cardgame_kyoshitsu.html"
										class="eventTop_linkBtn"
										>さらにくわしく</a
									>
								</div>
							</div>
						</div>

						<button
							class="eventTop_modal_close"
							data-micromodal-close
						>
							<img
								src="@/views/Event/Top/src/images/common/btn_modal_close.png"
								alt="閉じる"
							/>
						</button>
					</div>
				</div>

				<div
					id="modal_enjoy_join"
					class="eventTop_modal"
					aria-hidden="true"
				>
					<div class="eventTop_modal_overlay" data-micromodal-close>
						<div class="eventTop_modal_cover"></div>
						<div
							class="eventTop_modal_dialog"
							role="dialog"
							aria-modal="true"
							aria-labelledby="イベントの参加方法"
						>
							<div class="eventTop_modal_dialog_inner">
								<div class="eventTop_modal_spClose">
									<button
										data-micromodal-close
										class="eventTop_modal_spClose_btn"
									></button>
								</div>
								<div class="eventTop_modal_inner">
									<h2 class="eventTop_modal_title">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/enjoy/popup/join/title_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/enjoy/popup/join/title.png"
												alt="イベントの参加方法"
											/>
										</picture>
									</h2>

									<p class="eventTop_modal_text">
										自分にあったイベントや、近所で開催されているイベントを、まずはサイトから探してみよう！
									</p>

									<figure class="eventTop_modal_image">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/enjoy/popup/join/image_illust_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/enjoy/popup/join/image_illust.png"
												alt=""
											/>
										</picture>
									</figure>

									<div class="eventTop_modal_detail">
										<ol class="eventTop_modal_step">
											<li
												class="eventTop_modal_step_item"
											>
												<h3
													class="eventTop_modal_step_item_title"
												>
													まずはイベントを探そう！
												</h3>
												<p
													class="eventTop_modal_step_item_text"
												>
													自分のレベルに合ったイベントや<br />家の近くのイベントを探してみよう！
												</p>
											</li>
											<li
												class="eventTop_modal_step_item"
											>
												<h3
													class="eventTop_modal_step_item_title"
												>
													プレイヤーズクラブに登録しよう！
												</h3>
												<p
													class="eventTop_modal_step_item_text"
												>
													事前に登録が必要なイベントもあるので要注意！イベント情報をチェックしよう！
												</p>
											</li>
											<li
												class="eventTop_modal_step_item"
											>
												<h3
													class="eventTop_modal_step_item_title"
												>
													もちものを準備してイベントへ！
												</h3>
												<p
													class="eventTop_modal_step_item_text"
												>
													対戦デッキやダメカンなど、イベント参加に必要なものを持ってイベントに参加しよう！イベントによっては、手ぶらで参加できるものもあるよ。
												</p>
											</li>
										</ol>
									</div>

									<a
										href="https://www.pokemon-card.com/event/beginner/"
										class="eventTop_linkBtn"
										>さらにくわしく</a
									>
								</div>
							</div>
						</div>

						<button
							class="eventTop_modal_close"
							data-micromodal-close
						>
							<img
								src="@/views/Event/Top/src/images/common/btn_modal_close.png"
								alt="閉じる"
							/>
						</button>
					</div>
				</div>

				<!-- チャレンジ モーダル-->
				<div
					id="modal_challenge_point"
					class="eventTop_modal"
					aria-hidden="true"
				>
					<div class="eventTop_modal_overlay" data-micromodal-close>
						<div class="eventTop_modal_cover"></div>
						<div
							class="eventTop_modal_dialog"
							role="dialog"
							aria-modal="true"
							aria-labelledby="チャンピオンシップポイントを貯めよう"
						>
							<div class="eventTop_modal_dialog_inner">
								<div class="eventTop_modal_spClose">
									<button
										data-micromodal-close
										class="eventTop_modal_spClose_btn"
									></button>
								</div>
								<div class="eventTop_modal_inner">
									<h2 class="eventTop_modal_title">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/challenge/popup/point/title_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/challenge/popup/point/title.png"
												alt="チャンピオンシップポイントを貯めよう"
											/>
										</picture>
									</h2>

									<p class="eventTop_modal_text">
										チャンピオンシップポイントは、ポイント付与対象の大会に参加して、その成績によって付与されるよ。付与されるポイントは、各大会の規模や形式によって決められているよ。
									</p>

									<figure class="eventTop_modal_image">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/challenge/popup/point/image_illust_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/challenge/popup/point/image_illust.png"
												alt=""
											/>
										</picture>
									</figure>

									<p class="eventTop_modal_lead">
										<span
											>ポイントを貯めて大会出場権利や</span
										><br />
										<span>賞品をゲットしよう！</span>
									</p>

									<p class="eventTop_modal_text">
										大会に参加して、一定以上のポイントを貯めることで、ポケモンワールドチャンピオンシップスへの出場権利や賞品を手に入れられるよ。
									</p>

									<div class="eventTop_modal_check">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/challenge/popup/point/image_check_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/challenge/popup/point/image_check.png"
												alt="ポイントを貯めて獲得できる賞品も!"
											/>
										</picture>
									</div>

									<a
										href="https://www.pokemon-card.com/info/2000/20000101_000811.html"
										class="eventTop_linkBtn"
										>さらにくわしく</a
									>
								</div>
							</div>
						</div>

						<button
							class="eventTop_modal_close"
							data-micromodal-close
						>
							<img
								src="@/views/Event/Top/src/images/common/btn_modal_close.png"
								alt="閉じる"
							/>
						</button>
					</div>
				</div>

				<div
					id="modal_challenge_howto"
					class="eventTop_modal"
					aria-hidden="true"
				>
					<div class="eventTop_modal_overlay" data-micromodal-close>
						<div class="eventTop_modal_cover"></div>
						<div
							class="eventTop_modal_dialog"
							role="dialog"
							aria-modal="true"
							aria-labelledby="プレイヤーズクラブの使い方"
						>
							<div class="eventTop_modal_dialog_inner">
								<div class="eventTop_modal_spClose">
									<button
										data-micromodal-close
										class="eventTop_modal_spClose_btn"
									></button>
								</div>
								<div class="eventTop_modal_inner">
									<h2 class="eventTop_modal_title">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/challenge/popup/howto/title_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/challenge/popup/howto/title.png"
												alt="プレイヤーズクラブの使い方"
											/>
										</picture>
									</h2>

									<p class="eventTop_modal_text">
										ポケモンカードゲームプレイヤーズクラブは、イベント情報の閲覧やエントリーができる会員制サイトだよ。登録することで、イベントへの応募やマイデッキの登録、獲得したチャンピオンシップポイントの確認ができるようになるよ。
									</p>

									<div class="eventTop_modal_detail">
										<figure
											class="eventTop_modal_detail_image"
										>
											<picture>
												<source
													srcset="
														@/views/Event/Top/src/images/challenge/popup/howto/image_illust_sp.png
													"
													media="(max-width: 768px)"
												/>
												<img
													src="@/views/Event/Top/src/images/challenge/popup/howto/image_illust.png"
													alt=""
												/>
											</picture>
										</figure>
										<p class="eventTop_modal_detail_text">
											マイページから、現在所持しているチャンピオンシップポイントや予約しているイベントの確認をいつでも行うことができます。
										</p>

										<img
											class="eventTop_modal_detail_image_sp"
											src="@/views/Event/Top/src/images/challenge/popup/howto/image_illust-02_sp.png"
											alt=""
										/>
									</div>

                                    <router-link
                                        :to="{name: 'Faq'}"
                                        class="eventTop_linkBtn"
                                    ><span>プレイヤーズクラブの</span>
                                    <span>よくある質問はこちら</span></router-link>
								</div>
							</div>
						</div>

						<button
							class="eventTop_modal_close"
							data-micromodal-close
						>
							<img
								src="@/views/Event/Top/src/images/common/btn_modal_close.png"
								alt="閉じる"
							/>
						</button>
					</div>
				</div>
				<div
					id="modal_challenge_schedule"
					class="eventTop_modal"
					aria-hidden="true"
				>
					<div class="eventTop_modal_overlay" data-micromodal-close>
						<div class="eventTop_modal_cover"></div>
						<div
							class="eventTop_modal_dialog"
							role="dialog"
							aria-modal="true"
							aria-labelledby="チャンピオンシップシリーズ2025 スケジュール"
						>
							<div class="eventTop_modal_dialog_inner">
								<div class="eventTop_modal_spClose">
									<button
										data-micromodal-close
										class="eventTop_modal_spClose_btn"
									></button>
								</div>
								<div class="eventTop_modal_inner">
									<h2 class="eventTop_modal_title">
										<picture>
											<source
												srcset="
													@/views/Event/Top/src/images/challenge/popup/schedule/title_sp.png
												"
												media="(max-width: 768px)"
											/>
											<img
												src="@/views/Event/Top/src/images/challenge/popup/schedule/title.png"
												alt="チャンピオンシップシリーズ2025 スケジュール"
											/>
										</picture>
									</h2>

									<ul class="eventTop_modal_league">
										<li
											class="eventTop_modal_league_item"
											data-type="champions"
										>
											<div
												class="eventTop_modal_league_item_head"
											>
												<h3
													class="eventTop_modal_league_item_title"
												>
													<img
														src="@/views/Event/Top/src/images/challenge/popup/schedule/title-01.png"
														alt="チャンピオンズリーグ"
													/>
												</h3>
											</div>

											<div
												class="eventTop_modal_league_item_body"
											>
												<figure
													class="eventTop_modal_league_item_image"
												>
													<picture>
														<source
															srcset="
																@/views/Event/Top/src/images/challenge/popup/schedule/image-01_sp.png
															"
															media="(max-width: 768px)"
														/>
														<img
															src="@/views/Event/Top/src/images/challenge/popup/schedule/image-01.png"
															alt=""
														/>
													</picture>
												</figure>
											</div>
										</li>
										<li
											class="eventTop_modal_league_item"
											data-type="city"
										>
											<div
												class="eventTop_modal_league_item_head"
											>
												<h3
													class="eventTop_modal_league_item_title"
												>
													<img
														src="@/views/Event/Top/src/images/challenge/popup/schedule/title-02.png"
														alt="シティリーグ"
													/>
												</h3>
											</div>

											<div
												class="eventTop_modal_league_item_body"
											>
												<figure
													class="eventTop_modal_league_item_image"
												>
													<picture>
														<source
															srcset="
																@/views/Event/Top/src/images/challenge/popup/schedule/image-02_sp.png
															"
															media="(max-width: 768px)"
														/>
														<img
															src="@/views/Event/Top/src/images/challenge/popup/schedule/image-02.png"
															alt=""
														/>
													</picture>
												</figure>
											</div>
										</li>
										<li
											class="eventTop_modal_league_item"
											data-type="trainer"
										>
											<div
												class="eventTop_modal_league_item_head"
											>
												<h3
													class="eventTop_modal_league_item_title"
												>
													<img
														src="@/views/Event/Top/src/images/challenge/popup/schedule/title-03.png"
														alt="トレーナーズリーグ"
													/>
												</h3>
											</div>

											<div
												class="eventTop_modal_league_item_body"
											>
												<p
													class="eventTop_modal_league_item_body_text"
												>
													1年を通して開催
												</p>
											</div>
										</li>
									</ul>

									<p class="eventTop_modal_text">
										年間スケジュールの最後には、ポケモンジャパンチャンピオンシップスを開催予定です。
										<br />
										諸般の事情により、上記のイベントは、日程変更または中止となる場合がございます。
									</p>

									<a
										href="https://www.pokemon-card.com/info/004565.html"
										class="eventTop_linkBtn"
									>
										<span>チャンピオンシップシリーズ</span>
										<span>2025シーズンについて</span>
									</a>
								</div>
							</div>
						</div>

						<button
							class="eventTop_modal_close"
							data-micromodal-close
						>
							<img
								src="@/views/Event/Top/src/images/common/btn_modal_close.png"
								alt="閉じる"
							/>
						</button>
					</div>
				</div>
			</div>

			<!-- SP用固定ナビ -->
			<aside class="eventTop_globalMenu" data-visible="false">
				<ul class="eventTop_globalMenu_list">
					<li>
                        <router-link
                            :to="{name: 'EventSearch'}"
                            class="eventTop_globalMenu_link"
                        >
							<i class="eventTop_globalMenu_link_icon">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M18.125 17.7188L13.2781 12.8711C14.0592 11.7977 14.4794 10.5041 14.4781 9.17656C14.4781 5.70195 11.6512 2.875 8.17656 2.875C4.70195 2.875 1.875 5.70195 1.875 9.17656C1.875 12.6512 4.70195 15.4781 8.17656 15.4781C9.50407 15.4794 10.7977 15.0592 11.8711 14.2781L16.7188 19.125L18.125 17.7188ZM8.17656 13.4879C7.32375 13.488 6.49007 13.2351 5.78095 12.7614C5.07183 12.2877 4.51913 11.6143 4.19274 10.8264C3.86635 10.0385 3.78093 9.17152 3.94728 8.33509C4.11364 7.49866 4.5243 6.73035 5.12733 6.12733C5.73035 5.5243 6.49866 5.11364 7.33509 4.94728C8.17152 4.78093 9.0385 4.86635 9.82638 5.19274C10.6143 5.51913 11.2877 6.07183 11.7614 6.78095C12.2351 7.49007 12.488 8.32375 12.4879 9.17656C12.4865 10.3196 12.0319 11.4154 11.2236 12.2236C10.4154 13.0319 9.31959 13.4865 8.17656 13.4879Z"
										class="eventTop_globalMenu_link_icon_fill"
									/>
								</svg>
							</i>
							イベント<br />検索
                        </router-link>
					</li>
					<li>
                        <router-link
                            :to="{name: 'Mypage'}"
                            class="eventTop_globalMenu_link"
                        >
							<i class="eventTop_globalMenu_link_icon">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 21 20"
									fill="none"
								>
									<path
										d="M6 5C8.76142 2.23858 13.2386 2.23858 16 5C18.7614 7.76142 18.7614 12.2386 16 15C13.2386 17.7614 8.76142 17.7614 6 15"
										stroke-width="2"
										stroke-linecap="round"
										class="eventTop_globalMenu_link_icon_stroke"
									/>
									<path
										d="M1.54102 8.936C0.98873 8.936 0.541015 9.38371 0.541015 9.936C0.541015 10.4883 0.98873 10.936 1.54101 10.936L1.54102 8.936ZM9.71355 6.06123C9.33963 5.65478 8.70701 5.62842 8.30057 6.00235C7.89412 6.37628 7.86776 7.0089 8.24169 7.41534L9.71355 6.06123ZM8.24169 12.5846C7.86776 12.991 7.89412 13.6236 8.30057 13.9976C8.70701 14.3715 9.33962 14.3451 9.71355 13.9387L8.24169 12.5846ZM11.9784 9.99995L12.7143 10.677C13.0664 10.2943 13.0664 9.70561 12.7143 9.3229L11.9784 9.99995ZM1.54101 10.936L11.9784 10.936L11.9784 8.936L1.54102 8.936L1.54101 10.936ZM8.24169 7.41534L11.2424 10.677L12.7143 9.3229L9.71355 6.06123L8.24169 7.41534ZM11.2424 9.3229L8.24169 12.5846L9.71355 13.9387L12.7143 10.677L11.2424 9.3229Z"
										class="eventTop_globalMenu_link_icon_fill"
									/>
								</svg>
							</i>
							プレイヤーズ<br />クラブ
                        </router-link>
					</li>
				</ul>
			</aside>
</template>

<script>
import { App } from './src/ts/main'
import { backfaceFixed } from './src/ts/util/backfaceFixed'

import NewsListItem from '@/views/News/component/NewsListItem.vue'
import EventResultListItem from "../component/EventResultListItem.vue"

export default {
    name: 'EventTopPage',
    components: {
        EventResultListItem,
        NewsListItem,
    },
    data() {
        return {
            page: {
                title: 'イベント',
                pageTitle: 'イベント | ポケモンカードゲーム プレイヤーズクラブ',
                desc: 'ポケモンカードゲームのイベント情報を確認したりイベントへのエントリーができる公式サイトです。',
                breadcrumb: [],
            },
            app: null,
            event: [],
            news: [],
        }
    },
    computed: {
        selectedEventPage() {
            return this.$store.state.selectedEventPage;
        },
    },
    created: function() {
        document.title = this.page.pageTitle;
        document.querySelector("meta[name='description']").setAttribute('content', this.page.desc)

        this.searchEventResults()
        this.searchInformations()

        // new App()の前にlocation.hashの処理を行う
        // $router.push()ではapp.init()の処理中にlocatin.hashを正しく取得できないため直接書き換える
        if (!this.$route.hash && this.selectedEventPage) {
            // 以前イベントページの選択をしていた場合はそちらへ飛ばす
            window.location.hash = this.selectedEventPage
        }

        if (!this.selectedEventPage) {
            window.location.hash = ""
        } else {
            window.location.hash = this.selectedEventPage
        }
    },
    mounted: function() {
        if (this.app) {
            // 複数回new App()をするとclick eventが二重登録されるため
            return
        }

        // アニメーション再生時に後ろのコンテンツが映りこむことがあるのでTOPに戻す
        window.scrollTo({top: 0})
        this.$nextTick(function() {
            this.app = new App()
        })
    },
    beforeUnmount: function() {
        // ページを離れるときはスクロール禁止を解除する
        backfaceFixed(false)
    },
    methods: {
        setHash(hash) {
            const eventHashList = ['#enjoy', '#challenge']
            if (eventHashList.includes(hash)) {
                this.$store.commit('setSelectedEventPage', hash)
            }
        },
        searchEventResults: function () {
            const targetUrl = "/event_search"
            let params = {
                offset: 0,
                limit: 3,
                order: 4,
                result_resist: 1,
                event_type: [
                    '3:1',
                    '3:2',
                    '3:7',
                ],
                isLoadingHidden: true
            }
            this.axios.get(targetUrl, { params: params })
            .then((response) => {
                this.event = response.data.event
            })
        },
        searchInformations() {
            const targetUrl = "/api/information"
            let params = {
                offset: 0,
                limit: 3,
                isLoadingHidden: true
            }
            this.axios.get(targetUrl, { params: params })
            .then((response) => {
                this.news = response.data.information
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@use "./src/scss/style.scss";

.eventTop {
    // 「TO PAGE TOP」が押せなくなるのでボタン分のmarginを開ける
    margin-bottom: 56px;
}
</style>

<style lang="scss">
@use "./src/scss/common.scss" as *;

// 後から追加した<iframe>のタグにはVueのIDが付かずにstyleが適用されないのでglobalに追加する
#modal_youtube .eventTop_modal_youtube_iframe {
    @include full;
}
</style>
