<template>
    <pcg-page :page-settings="pageSettings">
        <div class="players-logo">
            <img src="@/assets/img/common/players_logo2x.png">
        </div>
        <h1
            v-if="pageSettings.breakedTitle"
            class="c-title c-title-page"
            v-html="pageSettings.breakedTitle"
        ></h1>
        <h1 v-else class="c-title c-title-page">{{ pageSettings.title }}</h1>
        <slot></slot>
        <div class="u-text-center bottom-image">
            <img src="@/assets/img/common/players_logo2x.png">
        </div>
    </pcg-page>
</template>

<script>
import pcgPage from '@/component/Page.vue'

export default {
    name: 'LoginPageLayout',
    components: {
        pcgPage,
    },
    props: {
        pageSettings: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
.players-logo {
    padding-top: 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    img {
        width: 250px;
    }
}
.c-title-page {
    margin-bottom: 34px;
}
.bottom-image {
    margin-bottom: 42px;
    padding-top: 80px;
    img {
        width: 250px;
    }
}

@include g.sp {
    .players-logo {
        padding-top: 0;
        margin-bottom: 20px;
    }
    .players-logo img {
        width: 190px;
    }
    .c-title-page {
        padding: 0 20px 32px;
        margin-bottom: 0;
        font-size: 32px;
    }
    .bottom-image {
        padding-top: 48px;
        margin-bottom: 18px;

        img {
            width: 168px;
        }
    }
}
</style>
